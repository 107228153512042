import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import AddAffiliate from "./AddAffiliate";
// import {, setNewClientAddedStatus, addClient} from "../../redux/clientsReducer";
import {AddAffiliate as AddAffiliateFunc, setNewAffAdded, setLoading, getAffs} from "../../redux/affReducer";

const AddAffiliateContainer : React.FC = (props) => {

    return (
        <AddAffiliate {...props}/>
    );
};

let mapStateToProps:any = (state:any) => {
    return {
        affs: state.affs,
        campaigns: state.campaigns,
    }
};

export default compose(connect(mapStateToProps,{AddAffiliateFunc,setLoading,setNewAffAdded,getAffs}))(AddAffiliateContainer);
