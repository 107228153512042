import React from 'react'
import EditClient from "./EditClient";
import {compose} from "redux";
import {connect} from "react-redux";
import {getClientDetails, setLoading, updateClient} from "../../redux/clientsReducer";

const EditClientContainer: React.FC = (props) => {

    return <EditClient {...props}/>

}

let mapStateToProps:any = (state:any) => {
    return {
        auth: state.auth,
        clients: state.clients,
        app: state.app,
        desks: state.desks,
        brands: state.brands,
        statuses: state.statuses,
        campaigns: state.campaigns,
        countries: state.countries,
    }
}

// export default EditClientContainer
export default compose(connect(mapStateToProps,{setLoading,getClientDetails,updateClient}))(EditClientContainer);
