import React, { useEffect, useState } from "react";
import Tree from "react-d3-tree";
import { Card, ConfigProvider, Skeleton, Spin, theme } from "antd";
import css from './UserHierarchy.module.css';
import { Link } from "react-router-dom";

interface User {
    userID: string;
    fullName: string;
    supervisor: string;
}

interface TreeNode {
    name: string;
    attributes?: Record<string, string | number | boolean>;
    children?: TreeNode[];
    __rd3t?: { collapsed: boolean };
}

const buildHierarchy = (users: User[]): TreeNode | null => {
    const userMap: Record<string, TreeNode> = {};
    let root: TreeNode | null = null;

    users.forEach(user => {
        userMap[user.userID] = { name: user.fullName, attributes: { ID: user.userID }, children: [], __rd3t: { collapsed: false } };
    });

    users.forEach(user => {
        if (user.supervisor === "0") {
            root = userMap[user.userID];
        } else if (userMap[user.supervisor]) {
            userMap[user.supervisor].children?.push(userMap[user.userID]);
        }
    });

    return root;
};

const UserHierarchy: React.FC = (props: any) => {
    const [treeData, setTreeData] = useState<TreeNode | null>(null);
    const [dimensions, setDimensions] = useState({ width: 800, height: 600 });

    useEffect(() => {
        if(props.users.users.length === 0){
            props.getUsers()
        }
    }, []);

    useEffect(() => {
        const hierarchy = buildHierarchy(props.users.users);
        setTreeData(hierarchy);

        const updateSize = () => {
            setDimensions({ width: window.innerWidth - 40, height: window.innerHeight - 100 });
        };

        updateSize();
        window.addEventListener("resize", updateSize);
        return () => window.removeEventListener("resize", updateSize);
    }, [props.users.users]);

    useEffect(() => {
        const hierarchy = buildHierarchy(props.users.users);
        setTreeData(hierarchy);
    }, [props.users.users]);

    const colorTextBase = props.app.theme === 'dark' ? "#fff" : "#333";
    const primary = "#b800ff";

    return (
        <div>
            {treeData ? (
                <div style={{ width: "100%", height: "85vh" }}>
                    <Tree
                        data={treeData}
                        orientation="vertical"
                        pathFunc="step"
                        separation={{ siblings: 1.2, nonSiblings: 1 }}
                        nodeSize={{ x: 200, y: 150 }}
                        pathClassFunc={() => css.customLink}
                        translate={{ x: dimensions.width / 2, y: dimensions.height / 4 }} // Центрирование
                        renderCustomNodeElement={({ nodeDatum, toggleNode }) => {
                            const hasChildren = nodeDatum.children && nodeDatum.children.length > 0;
                            return (
                                <g>
                                    <circle
                                        r={hasChildren ? 20 : 10} // Узлы с детьми больше
                                        fill={hasChildren ? "url(#gradientParent)" : "url(#gradient)"}
                                        stroke="none"
                                        strokeWidth="1.5"
                                        onClick={toggleNode}
                                        style={{ cursor: "pointer", transition: "transform 0.2s ease-in-out" }}
                                        onMouseEnter={(e) => e.currentTarget.setAttribute("transform", "scale(1.1)")}
                                        onMouseLeave={(e) => e.currentTarget.setAttribute("transform", "scale(1)")}
                                    />
                                    <defs>
                                        <radialGradient id="gradient" cx="50%" cy="50%" r="50%">
                                            <stop offset="0%" stopColor="#d977ff" />
                                            <stop offset="100%" stopColor={primary} />
                                        </radialGradient>
                                        <radialGradient id="gradientParent" cx="50%" cy="50%" r="50%">
                                            <stop offset="0%" stopColor="#ff77ff" />
                                            <stop offset="100%" stopColor={primary} />
                                        </radialGradient>
                                    </defs>
                                    <Link to={`/userView/${nodeDatum.attributes?.ID}`}>
                                        <text x={hasChildren ? 30 : 25} y={5} fill={colorTextBase} fontSize="14" stroke="none" textAnchor="start" style={{ fontWeight: "bold" }}>
                                            {nodeDatum.name}
                                        </text>
                                    </Link>
                                </g>
                            );
                        }}
                    />
                </div>
            ) : (
                <Skeleton />
            )}
        </div>
    );
};

export default UserHierarchy;