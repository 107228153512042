import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {changeStatus, getClientDetails, setLoading} from "../../redux/clientsReducer";
import ClientView from './ClientView';
import { getClients } from '../../redux/clientsReducer';

const ClientViewContainer : React.FC = (props) => {
    return (
        <ClientView {...props}/>
    );
};

let mapStateToProps:any = (state:any) => {

    // debugger;
    return {
        auth: state.auth,
        clients: state.clients,
        brands: state.brands,
        desks: state.desks,
        campaigns: state.campaigns,
        users: state.users,
        statuses: state.statuses,
        countries: state.countries,
        app: state.app
    }
};

export default compose(connect(mapStateToProps,{getClients,setLoading,getClientDetails,changeStatus}))(ClientViewContainer);
