import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Form, FormProps, Input, Result, Select, Space, TreeSelect, Typography} from "antd";
import {Link, useNavigate} from "react-router-dom";
import css from './AddAffiliate.module.css'
import { EN } from '../../common/locales/en';
import {RU} from "../../common/locales/ru";
// import {translate} from "../../common/translate";
import { useTranslation } from 'react-i18next';
import ShowError from "../ShowError/ShowError";
import {addCampaign} from "../../redux/campaignsReducer";
import {AddAffiliate as AddAffiliateFunc, setNewAffAdded} from "../../redux/affReducer";
// import {setNewClientAddedStatus, setNewClientAddedStatusAC} from "../../redux/clientsReducer";



type FieldType = {
    title?: string | undefined;
    details?: string | undefined;
    isActive?: boolean | undefined;
};


const AddAffiliate: React.FC = (props: any) => {

    let navigate = useNavigate();

    const { t } = useTranslation('',{ keyPrefix: 'addAffiliate' });

    const [form] = Form.useForm();


    const onReset = () => {
        form.resetFields();
    };

    // let [loading, setLoading] = useState(false);
    // let [doTranslate, setDoTranslate] = useState(false);
    // useEffect(() => {
    //     if (props.clients.newClientAddedStatus) {
    //         props.setNewClientAddedStatus(false);
    //         // setShowSuccess(true);
    //         // setShowSuccessCounter(showSuccessCounter++);
    //         // console.log('showSuccessCounter - ' + showSuccessCounter)
    //     } else {
    //         // setShowSuccess(false)
    //     }
    // }, [])

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        console.log('Success:', values);

        props.AddAffiliateFunc(values.title,values.details,values.isActive);
        props.setLoading(true);
    };

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onClose = () => {
        props.setLoading(false);
        props.setNewAffAdded(false);
        onReset();
    }

    const goToList = () => {
        navigate('/affiliates')
        props.getAffs()
        props.setNewAffAdded(false);
        onReset();
    }


    const tailLayout = {
        // wrapperCol: {offset: 8, span: 16},
    };

    // const doTranslate: any = (key:string,value:string) => {
    //     return translate(key,value);
    // }

    // useEffect(()=>{
    //     if(props.app.language === 'ru'){
    //         doTranslate
    //     }
    // },[props.app.language])

    // console.log(props.affs)
    // debugger
    return (
        // false
        props.affs.newAffAdded
            ?
            <Result
                status="success"
                title={t('Affiliate Added Successfully!')}
                // subTitle={t('We advice you to setup this client\'s permissions')}
                extra={[
                    // <Button type="primary" key="permissions" disabled>
                    //     {t('Set Permissions')}
                    // </Button>,
                    <Button key="goToList" onClick={goToList}>
                        {t('Go to affiliates list')}
                    </Button>,
                    <Button key="close" onClick={onClose}>{t('Close/Add One More Affiliate') }</Button>,
                ]}
            />
            :
            <div>
                <Typography.Title className={'center'}>{t('Add new affiliate')}</Typography.Title>
                <Form className={`m_auto left`}

                      form={form}
                      name="basic"
                    // labelCol={{ span: 8 }}
                    // wrapperCol={{ span: 16 }}
                      style={{maxWidth: 400}}
                      initialValues={{
                          // email: 'email@mail.com',
                          // password: 'myPassword123',
                          // fullName: 'Michael Andrews',
                          // isActive: true,
                          // desk: 'Poland desk',
                          // role: 'Manager',
                          // brand: '42-Fin',
                          // supervisorID: 3
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      layout="vertical"
                >
                    {/*<ShowError errors={[props.campaign.newClientAddedErrorText !== '' ? t(props.campaign.newClientAddedErrorText) : '']} />*/}

                    <Form.Item<FieldType> label={t('Title')} name="title" rules={[{
                        required: true,
                    }]}><Input/></Form.Item>
                    {/*<Form.Item<FieldType> label={t('Affiliate')} name="affID" rules={[{*/}
                    {/*    required: true,*/}
                    {/*    // message: 'Please input email!'*/}
                    {/*}]}><Input/></Form.Item>*/}

                    <Form.Item<FieldType> label={t('Details')} name="details" rules={[{
                        required: true,
                        // message: 'Please input desk!'
                    }]}><Input/>
                        {/*<Select>*/}

                        {/*    {props.affs.affs.map((aff:any)=>{*/}
                        {/*        return aff.isActive === "1" ? <Select.Option key={aff.affID} value={aff.affID}>{aff.title}</Select.Option> : null;*/}
                        {/*    })}*/}
                        {/*    /!*<Select.Option value="Poland">Poland Desk</Select.Option>*!/*/}
                        {/*    /!*<Select.Option value="Spain">Spain Desk</Select.Option>*!/*/}
                        {/*    /!*<Select.Option value="German">German Desk</Select.Option>*!/*/}
                        {/*</Select>*/}
                    </Form.Item>


                    <Form.Item<FieldType> label="" name="isActive"
                                          valuePropName="checked"><Checkbox>{t('Active')}</Checkbox></Form.Item>


                    <Form.Item  {...tailLayout}
                        // wrapperCol={{ offset: 8, span: 16 }}
                    >

                        <Space>
                            <Button type="primary" htmlType="submit" loading={props.affs.loading} disabled={props.affs.loading}>
                                {t('Add Affiliate')}
                            </Button>

                            <Button htmlType="button" onClick={onReset} loading={props.affs.loading} disabled={props.affs.loading}>
                                {t('Reset')}
                            </Button>
                        </Space>
                    </Form.Item>

                </Form>

            </div>
    );
};

export default AddAffiliate;
