import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import Users from "./Users";
import {getUsers, setLoading} from "../../redux/usersReducer";

const UsersContainer : React.FC = (props) => {
    return (
        <Users {...props}/>
    );
};

let mapStateToProps:any = (state:any) => {
    return {
        auth: state.auth,
        desks: state.desks,
        users: state.users,
        app: state.app,
        brands: state.brands,
        statuses: state.statuses,
        campaigns: state.campaigns,
        countries: state.countries,
    }
};

export default compose(connect(mapStateToProps,{getUsers,setLoading}))(UsersContainer);
