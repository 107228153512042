import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    Form,
    FormProps,
    Input,
    Result,
    Select,
    Space,
    TreeSelect,
    TreeSelectProps,
    Typography
} from "antd";
import {Link} from "react-router-dom";
import css from './AddUser.module.css'
import { EN } from '../../common/locales/en';
import {RU} from "../../common/locales/ru";
// import {translate} from "../../common/translate";
import { useTranslation } from 'react-i18next';
import ShowError from "../ShowError/ShowError";
// import {setNewUserAddedStatus, setNewUserAddedStatusAC} from "../../redux/usersReducer";



type FieldType = {
    email?: string | undefined;
    password?: string | undefined;
    fullName?: string | undefined;
    isActive?: boolean | undefined;
    desk?: string | undefined;
    role?: string | undefined;
    brand?: string | undefined;
    createDate?: number | undefined;
    createdBy?: string | undefined;
    supervisorID?: number | undefined;
};


const AddUser: React.FC = (props: any) => {

    const { t } = useTranslation('',{ keyPrefix: 'addUser' });

    const [form] = Form.useForm();


    const onReset = () => {
        form.resetFields();
    };

    // let [loading, setLoading] = useState(false);
    // let [doTranslate, setDoTranslate] = useState(false);
    useEffect(() => {
        if (props.users.newUserAddedStatus) {
            props.setNewUserAddedStatus(false);
            // setShowSuccess(true);
            // setShowSuccessCounter(showSuccessCounter++);
            // console.log('showSuccessCounter - ' + showSuccessCounter)
        } else {
            // setShowSuccess(false)
        }
    }, [])

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        console.log('Success:', values);

        props.setNewUserData(values.email, values.password, values.fullName, values.isActive, values.desk, values.role, values.brand, values.supervisorID);
        props.setLoading(true);
    };

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onClose = () => {
        props.setLoading(false);
        props.setNewUserAddedStatus(false);
        onReset();
    }


    const tailLayout = {
        // wrapperCol: {offset: 8, span: 16},
    };

    interface User {
        userID: string;
        fullName: string;
        supervisor: string;
    }

    interface TreeNode {
        title: string;
        value: string;
        children: TreeNode[];
    }

    // const users: User[] = [
    //     { "userID": "1", "fullName": "Andrew Admin", "supervisor": "0" },
    //     { "userID": "2", "fullName": "Test User", "supervisor": "1" },
    //     { "userID": "3", "fullName": "Michael Andrews", "supervisor": "1" },
    //     { "userID": "4", "fullName": "Peter Parker", "supervisor": "1" },
    //     { "userID": "5", "fullName": "Steven Spielberg", "supervisor": "2" },
    //     { "userID": "6", "fullName": "Viola Violett", "supervisor": "3" }
    // ];

// Функция для построения дерева иерархии
    function buildHierarchy(users: User[]): TreeNode[] {
        const userMap: Record<string, TreeNode> = {};
        const root: TreeNode[] = [];

        // Создаем карту пользователей
        users.forEach(user => {
            userMap[user.userID] = { title: user.fullName, value: user.userID, children: [] };
        });

        // Строим иерархию
        users.forEach(user => {
            if (user.supervisor === "0") {
                root.push(userMap[user.userID]);
            } else if (userMap[user.supervisor]) {
                userMap[user.supervisor].children.push(userMap[user.userID]);
            }
        });

        return root;
    }

// Получаем иерархию
    const treeData: TreeNode[] = buildHierarchy(props.users.users);

    // console.log(treeData);

    const [supervisorValue,setSupervisorValue] = useState<string>();

    const onSupervisorChange = (newValue: string) => {
        setSupervisorValue(newValue);
    };


    const onPopupScroll: TreeSelectProps['onPopupScroll'] = (e) => {
        console.log('onPopupScroll', e);
    };

    // debugger
    return (props.users.newUserAddedStatus ?
            <Result
                status="success"
                title={t('users','User Added Successfully!')}
                subTitle={t('We advice you to setup this user\'s permissions')}
                extra={[
                    <Button type="primary" key="permissions" disabled>
                        {t('Set Permissions')}
                    </Button>,
                    <Button key="goToList">
                        <Link to="/users">{t('Go to users list')}</Link>
                    </Button>,
                    <Button key="close" onClick={onClose}>{t('Close/Add One More User') }</Button>,
                ]}
            />
            :
            <div>
                <Typography.Title className={'center'}>{t('Add new user')}</Typography.Title>
                <Form className={`m_auto left`}

                      form={form}
                      name="basic"
                    // labelCol={{ span: 8 }}
                    // wrapperCol={{ span: 16 }}
                      style={{maxWidth: 400}}
                      initialValues={{
                          email: 'email@mail.com',
                          password: 'myPassword123',
                          fullName: 'Michael Andrews',
                          isActive: true,
                          // desk: 'Poland desk',
                          // role: 'Manager',
                          // brand: '42-Fin',
                          // supervisorID: 3
                      }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      layout="vertical"
                >
                    <ShowError errors={[props.users.newUserAddedErrorText !== '' ? t(props.users.newUserAddedErrorText) : '']} />

                    <Form.Item<FieldType> label={t('Email')} name="email" rules={[{
                        required: true,
                        // message: 'Please input email!'
                    }]}><Input/></Form.Item>
                    <Form.Item<FieldType> label={t('Password')} name="password" rules={[{
                      required: true,
                      // message: 'Please input password!'
                    }]}>
                    <Input count={{show: true}}/></Form.Item>
                    <Form.Item<FieldType> label={t('Full Name')} name="fullName" rules={[{
                        required: true,
                    }]}><Input/></Form.Item>
                    {/*<Form.Item<FieldType> label={t('Last Name')} name="lastName" rules={[{*/}
                    {/*    required: true,*/}
                    {/*    // message: 'Please input lastName!'*/}
                    {/*}]}><Input/></Form.Item>*/}
                    <Form.Item<FieldType> label="" name="isActive"
                                          valuePropName="checked"><Checkbox>{t('Active')}</Checkbox></Form.Item>

                    <Form.Item label={t('Desk')} name="desk" rules={[{
                        required: true,
                        // message: 'Please input desk!'
                    }]}>
                        <Select>

                            {props.desks.desks.map((desk:any)=>{
                                return desk.isActive === "1" ? <Select.Option key={desk.deskID} value={desk.deskID}>{desk.name}</Select.Option> : null;
                            })}
                            {/*<Select.Option value="Poland">Poland Desk</Select.Option>*/}
                            {/*<Select.Option value="Spain">Spain Desk</Select.Option>*/}
                            {/*<Select.Option value="German">German Desk</Select.Option>*/}
                        </Select>
                    </Form.Item>

                    <Form.Item label={t('Role')} name="role" rules={[{
                        required: true,
                        // message: 'Please input role!'
                    }]}>
                        <Select>
                            <Select.Option value="0">SuperAdmin</Select.Option>
                            <Select.Option value="1">Admin</Select.Option>
                            <Select.Option value="2">Supervisor</Select.Option>
                            <Select.Option value="3">Manager</Select.Option>
                            <Select.Option value="4">Agent</Select.Option>
                            <Select.Option value="5">Guest</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label={t('Brand')} name="brand" rules={[{
                        required: true,
                        // message: 'Please input brand!'
                    }]}>
                        <Select>
                            {props.brands.brands.map((brand:any)=>{
                                return brand.isActive === "1" ? <Select.Option key={brand.brandID} value={brand.brandID} >{brand.name}</Select.Option> : null;
                            })}
                            {/*<Select.Option value="trd">TRD</Select.Option>*/}
                            {/*<Select.Option value="42">42</Select.Option>*/}
                            {/*<Select.Option value="bern">Bern</Select.Option>*/}
                        </Select>


                    </Form.Item>

                    <Form.Item label={t('Supervisor')} name="supervisorID"
                               rules={[{
                                   required: true,
                                   // message: 'Please input supervisorID!'
                               }]}>
                        <TreeSelect
                            showSearch
                            style={{ width: '100%' }}
                            value={supervisorValue}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder="Please select"
                            allowClear
                            treeDefaultExpandAll
                            onChange={onSupervisorChange}
                            treeData={treeData}
                            onPopupScroll={onPopupScroll}
                            filterTreeNode={(input, node) =>
                                (node as TreeNode).title.toLowerCase().includes(input.toLowerCase())
                            }

                            /*[
                                {title: 'none', value: '0', children: []},
                                {title: 'SuperAdmin', value: '1', children: [{title: 'Admin', value: '2'}]},
                                {title: 'Roman', value: '8', children: [{title: 'Tom', value: '9'}]},
                                {
                                    title: 'Anita',
                                    value: '5',
                                    children: [{title: 'Bill', value: '6'}, {title: 'Gabby', value: '7'},]
                                },
                                {title: 'Test', value: '17', children: []},
                                {title: 'Daniel', value: '3', children: [{title: 'Alex', value: '4'}]},
                            ]*/


                        />
                    </Form.Item>

                    <Form.Item  {...tailLayout}
                        // wrapperCol={{ offset: 8, span: 16 }}
                    >

                        <Space>
                            <Button type="primary" htmlType="submit" loading={props.users.loading} disabled={props.users.loading}>
                                {t('Add User')}
                            </Button>

                            <Button htmlType="button" onClick={onReset} loading={props.users.loading} disabled={props.users.loading}>
                                {t('Reset')}
                            </Button>
                        </Space>
                    </Form.Item>

                </Form>

            </div>
    );
};

export default AddUser;
