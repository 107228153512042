
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import css from "./ClientView.module.css";
import {
    Flex,
    Menu,
    MenuProps,
    notification,
    NotificationArgsProps,
    Select,
    Skeleton,
    Space,
    Splitter,
    Tabs
} from "antd";
import {setLoading} from "../../redux/clientsReducer";
import {AppstoreOutlined, CopyOutlined, MailOutlined, PhoneOutlined, SettingOutlined} from '@ant-design/icons';
import avatar from "../../imgs/profile-avatar.png";

const ClientView: React.FC = (props: any) => {

    type NotificationPlacement = NotificationArgsProps['placement'];
    type NotificationType = 'success' | 'info' | 'warning' | 'error';

    const [api, contextHolder] = notification.useNotification();

    const { t } = useTranslation('',{ keyPrefix: 'client' });
    const { id } = useParams();

    useEffect(()=>{
        // todo
        props.setLoading(true);
        props.getClientDetails(id)
    },[])

    // let loading = true;

    console.log(props.clients.selectedClient)
    console.log(props.app.appLoading)
    console.log(props.campaigns.campaigns)

    // brand
    let brandFind = props.brands.brands.find(
        (brand: any) => brand.brandID === props.clients.selectedClient.brand
    );
    let brand = brandFind ? <span key={brandFind.brandID}>{brandFind.name}</span> : null;

    // desk
    let deskFind = props.desks.desks.find(
        (desk: any) => desk.deskID === props.clients.selectedClient.desk
    );
    let desk = deskFind ? <span key={deskFind.deskID}>{deskFind.name}</span> : null;

    // manager
    let userFind = props.users.users.find(
        (user: any) => user.userID === props.clients.selectedClient.assignedTo
    );
    let manager = userFind ? <span key={userFind.userID}>{userFind.fullName}</span> : null;

    // campaign
    let campaignFind = props.campaigns.campaigns.find(
        (campaign: any) => campaign.campaignID === props.clients.selectedClient.campaignID
    );
    let campaign = campaignFind ? <span key={campaignFind.campaignID}>{campaignFind.campaignTitle}</span> : props.clients.selectedClient.campaignID;

    // country
    let countryFind = props.countries.countries.find(
        (country: any) => country.countryID === props.clients.selectedClient.country
    );
    let country = countryFind ? <span key={countryFind.countryID}>{countryFind.name}</span> : null;
    let statusChanged = (value: any,clientID: number) => {
        props.changeStatus(clientID,value.value)
        openNotification('Status changed')
    }
    const statusFind = props.statuses.statuses.find(
        (status: any) => status.statusID === props.clients.selectedClient.status
    );

    let statuses_arr: any = [];

    {props.statuses.statuses.map((st:any,k:number)=>{
        return st.isActive === '1' ?
            statuses_arr.push({
                value: st.statusID,
                label: <div key={st.statusID} style={{  backgroundColor: st.bgСolor !== '' ? st.bgСolor : 'transparent' , color: st.color, textAlign: "center", borderRadius: 5}}>{st.name}</div> ,
            })
            : null;
    })}
    // console.log(statuses_arr)
    let status = statusFind ?
        <Select labelInValue className={css.statusSelect} defaultValue={statusFind.statusID}
                options={statuses_arr} variant="borderless"
                onChange={(v)=>statusChanged(v,props.clients.selectedClient.clientID)}
        >
        </Select>
        : null;


    const copyPhone = () => {
        navigator.clipboard.writeText(props.clients.selectedClient.phone)
        console.log('copied')
        openNotification('Phone copied');
    }
    const copyEmail = () => {
        navigator.clipboard.writeText(props.clients.selectedClient.email)
        console.log('copied')
        openNotification('Email copied');
    }


    const openNotification = (message:string, placement: NotificationPlacement = 'bottomLeft', type:NotificationType = 'success') => {
        api[type]({
            message: t(message),
            // description: 'descr',
            placement,
        });
    };


    // comments, <br/>
    // calls, <br/>
    // emails, <br/>
    // sms

    const comunicationTabs:any = [
        {
            label: "Comments",
            key: 1,
            children: `Content of card tab Comments`,
        },
        {
            label: "Calls",
            key: 2,
            children: `Content of card tab Calls`,
        },
        {
            label: "Emails",
            key: 3,
            children: `Content of card tab Emails`,
        },
        {
            label: "SMS",
            key: 4,
            children: `Content of card tab SMS`,
        },

    ]

    return (
        (props.clients.loading || props.app.appLoading) ? <>
            <Skeleton></Skeleton>
            </> :
        <>
            {contextHolder}
            {/*<Link to="/clients" className={css.link}>{t('Clients List')}</Link>*/}

            <div>

                <Splitter
                    // style={{  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}
                >
                    <Splitter.Panel
                        defaultSize="370"
                        resizable={false}
                    >
                        {/*main info*/}
                        <Flex wrap gap="small" vertical={false} className={css.main_info}>


                            <Splitter
                                // style={{  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}
                            >
                                <Splitter.Panel
                                    defaultSize="25%"
                                    resizable={false}
                                >
                                    <Flex wrap gap="small" vertical={true} className={css.avatarDiv}>
                                        <img src={avatar} alt="avatar" className={css.avatar}/>
                                        <div>#{props.clients.selectedClient.clientID} <CopyOutlined className={css.copyPhone} title="copy" /></div>
                                        <div>Linked accounts?</div>

                                    </Flex>
                                </Splitter.Panel>
                                <Splitter.Panel>

                                    <Flex wrap gap="small" vertical={true} className={css.clientDetails}>
                                        <h2>{props.clients.selectedClient.fullName}</h2>
                                        <div className={css.emailDiv}><Space size="middle"><MailOutlined /><Link to={"mailto:"+props.clients.selectedClient.email}>{props.clients.selectedClient.email}</Link> <CopyOutlined className={css.copyEmail} title="copy" onClick={copyEmail} /></Space></div>
                                        <div className={css.phoneDiv}><PhoneOutlined /> {props.clients.selectedClient.phone} <CopyOutlined className={css.copyPhone} title="copy" onClick={copyPhone} /></div>
                                        <div>{status}
                                        </div>
                                        <div>log in as user</div>
                                    </Flex>
                                </Splitter.Panel>
                            </Splitter>








                        </Flex>
                    </Splitter.Panel>
                    <Splitter.Panel>
                        {/*details*/}
                        <Flex wrap gap="small"  vertical={false} className={css.details}>

                            <Flex wrap gap="small" vertical={true} className={css.flex}>
                                <div>Brand - {brand}</div>
                                <div>Desk - {desk}</div>
                                <div>Manager - {manager}</div>
                                <div>Campaign - {campaign}</div>
                                <div>Offer</div>
                                <div>Country -  {country}</div>
                                <div>Local date-time</div>
                                <div>Registration date - </div>
                                <div>FTD Date - </div>
                                <div>Verified Status - </div>
                                <div>Gender - </div>
                                <div>Allow Marketing Communication - Yes/No</div>
                                <div>Reputation</div>
                                <div>KYC FILES</div>
                                <div>Marketing Info</div>
                                <div>Login History</div>
                                <div>Audit Log</div>





                            </Flex>
                            <Flex wrap gap="small" vertical={true} className={css.flex}>
                                <div>Comunication history </div>

                                <Tabs
                                    defaultActiveKey="1"
                                    type="card"
                                    size="small"
                                    style={{ marginBottom: 32 }}
                                    items={comunicationTabs}
                                />

                            </Flex>
                            <Flex wrap gap="small" vertical={true} className={css.flex}>
                                <div>Trading Accounts - <br/>
                                    currency, <br/>
                                    ballance, <br/>
                                    transactions, <br/>
                                    demo-real, <br/>
                                    platform(MT4-CFD), <br/>
                                    status (active-suspended-blocked), <br/>
                                    withdrawal </div>
                            </Flex>
                        </Flex>

                    </Splitter.Panel>
                </Splitter>


            </div>
        </>

    );
};

export default ClientView;
