import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import UserHierarchy from "./UserHierarchy";
import {getUsers} from "../../redux/usersReducer";

const UserHierarchyContainer : React.FC = (props) => {

    return (
        <UserHierarchy {...props}/>
    );
};

let mapStateToProps:any = (state:any) => {
    return {
        users: state.users,
        app: state.app,
    }
};

export default compose(connect(mapStateToProps,{getUsers}))(UserHierarchyContainer);
