import React, {useEffect} from 'react'
import {useParams} from "react-router-dom";
import {Button, Checkbox, Form, FormProps, Input, Select, Skeleton, Space, TreeSelect, Typography} from "antd";
import ShowError from "../ShowError/ShowError";
import {useTranslation} from "react-i18next";
import {updateClient} from "../../redux/clientsReducer";

type FieldType = {
    fullName?: string | undefined;
    phone?: string | undefined;
    email?: string | undefined;
    password?: string | undefined;
    isActive?: boolean | undefined;
    desk?: string | undefined;
    type?: string | undefined;
    brand?: string | undefined;
    createDate?: number | undefined;
    createdBy?: string | undefined;
    address?: string | undefined;
    status?: string | undefined;
    country?: string | undefined;
    assignedToID?: number | undefined;
    campaignID?: number | undefined;
};
const EditClient: React.FC = (props:any) => {
    const { id } = useParams();
    const { t } = useTranslation('',{ keyPrefix: 'editClient' });

    const [form] = Form.useForm();
    useEffect(()=>{
        props.setLoading(true);
        props.getClientDetails(id)
            .then(()=>{
                onReset()
            })

    },[])

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        console.log('Success:', values);

        props.updateClient(
            {
                clientID: id,
                email: values.email,
                password: values.password,
                fullName: values.fullName,
                isActive: values.isActive,
                deskID: values.desk,
                typeID: values.type,
                brandID: values.brand,
                assignedToID: values.assignedToID,
                phone: values.phone,
                address: values.address,
                statusID: values.status,
                countryID: values.country,
                campaignID: values.campaignID
            });
        props.setLoading(true);
    };

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const tailLayout = {
        // wrapperCol: {offset: 8, span: 16},
    };
    const onReset = () => {
        form.resetFields();
    };

    return (props.clients.loading || props.app.appLoading) ? <>
        <Skeleton></Skeleton>
    </> :
        <div>
            <Typography.Title className={'center'}>{t('Edit client')}</Typography.Title>
            <Form className={`m_auto left`}

                  form={form}
                  name="basic"
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                  style={{maxWidth: 400}}
                  initialValues={{
                      email: props.clients.selectedClient.email,
                      password: props.clients.selectedClient.password,
                      fullName: props.clients.selectedClient.fullName,
                      isActive: props.clients.selectedClient.isActive === '1',
                      phone: props.clients.selectedClient.phone,
                      desk: props.clients.selectedClient.deskID,
                      role: props.clients.selectedClient.roleID,
                      brand: props.clients.selectedClient.brandID,
                      supervisorID: props.clients.selectedClient.assignedToID,
                      address: props.clients.selectedClient.address,
                      type: props.clients.selectedClient.typeID,
                      status: props.clients.selectedClient.statusID,
                      campaignID: props.clients.selectedClient.campaignID,
                      country: props.clients.selectedClient.countryID,
                      assignedToID: props.clients.selectedClient.assignedToID,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
            >
                <ShowError errors={[props.clients.newClientAddedErrorText !== '' ? t(props.clients.newClientAddedErrorText) : '']} />
                <Typography.Text>
                    Client ID - {props.clients.selectedClient.assignedTo}
                </Typography.Text>
                <Form.Item<FieldType> label={t('Full Name')} name="fullName" rules={[{
                    required: true,
                }]}><Input/></Form.Item>
                <Form.Item<FieldType> label={t('Phone')} name="phone" rules={[{
                    required: true,
                    // message: 'Please input email!'
                }]}><Input/></Form.Item>
                <Form.Item<FieldType> label={t('Email')} name="email" rules={[{
                    required: true,
                    // message: 'Please input email!'
                }]}><Input/></Form.Item>
                <Form.Item<FieldType> label={t('Password')} name="password" rules={[{
                    required: true,
                    // message: 'Please input password!'
                }]}>
                    <Input count={{show: true}}/></Form.Item>
                <Form.Item<FieldType> label={t('Address')} name="address" rules={[{
                    required: false,
                    // message: 'Please input email!'
                }]}><Input/></Form.Item>
                {/*<Form.Item<FieldType> label={t('Last Name')} name="lastName" rules={[{*/}
                {/*    required: true,*/}
                {/*    // message: 'Please input lastName!'*/}
                {/*}]}><Input/></Form.Item>*/}
                <Form.Item<FieldType> label="" name="isActive"
                                      valuePropName="checked"><Checkbox>{t('Active')}</Checkbox></Form.Item>

                <Form.Item label={t('Desk')} name="desk" rules={[{
                    required: true,
                    // message: 'Please input desk!'
                }]}>
                    <Select>
                        {props.desks.desks.map((desk:any)=>{
                            return desk.isActive === "1" ? <Select.Option key={desk.deskID} value={desk.deskID}
                                                                          // selected={desk.deskID === props.clients.selectedClient.desk}
                            >{desk.name}</Select.Option> : null;
                        })}
                        {/*<Select.Option value="Poland">Poland Desk</Select.Option>*/}
                        {/*<Select.Option value="Spain">Spain Desk</Select.Option>*/}
                        {/*<Select.Option value="German">German Desk</Select.Option>*/}
                    </Select>
                </Form.Item>
                <Form.Item label={t('Type')} name="type" rules={[{
                    required: true,
                }]}>
                    <Select>
                        <Select.Option key='1' value="1"
                                       // selected={'1' === props.clients.selectedClient.type}
                        >Lead</Select.Option>
                        <Select.Option key='2' value="2"
                                       // selected={'2' === props.clients.selectedClient.type}
                        >Client</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label={t('Status')} name="status" rules={[{
                    required: true,
                }]}>
                    <Select>
                        {props.statuses.statuses.map((status:any)=>{
                            return status.isActive === "1" ?
                                <Select.Option key={status.statusID}
                                               value={status.statusID} style={{ backgroundColor: status.bgСolor !== '' ? status.bgСolor : '#000', color: status.color}}
                                               selected={status.statusID === props.clients.selectedClient.status}
                                >{status.name}</Select.Option> : null;
                        })}
                        {/*<Select.Option value="hot">hot</Select.Option>*/}
                        {/*<Select.Option value="new">new</Select.Option>*/}
                        {/*<Select.Option value="no potential">no potential</Select.Option>*/}
                        {/*<Select.Option value="no answer">no answer</Select.Option>*/}
                    </Select>
                </Form.Item>
                {/*
                    <Form.Item label={t('Role')} name="role" rules={[{
                        required: true,
                        // message: 'Please input role!'
                    }]}>
                        <Select>
                            <Select.Option value="SuperAdmin">SuperAdmin</Select.Option>
                            <Select.Option value="Admin">Admin</Select.Option>
                            <Select.Option value="Supervisor">Supervisor</Select.Option>
                            <Select.Option value="Manager">Manager</Select.Option>
                            <Select.Option value="Agent">Agent</Select.Option>
                            <Select.Option value="Guest">Guest</Select.Option>
                        </Select>
                    </Form.Item>*/}
                <Form.Item label={t('Campaign')} name="campaignID" rules={[{
                    required: true,
                }]}>
                    <Select>
                        {props.campaigns.campaigns.map((campaign:any)=>{
                            return <Select.Option key={campaign.campaignID} value={campaign.campaignID} >{campaign.campaignTitle}</Select.Option>
                                // : null;
                        })}

                        {/*<Select.Option value="1">Poland 1</Select.Option>*/}
                        {/*<Select.Option value="2">Germany FB</Select.Option>*/}
                        {/*<Select.Option value="3">ProfitHunters PL</Select.Option>*/}
                        {/*<Select.Option value="4">TRD 22</Select.Option>*/}
                        {/*<Select.Option value="5">Test</Select.Option>*/}
                        {/*<Select.Option value="6">Poland hot manual</Select.Option>*/}
                    </Select>
                </Form.Item>

                <Form.Item label={t('Brand')} name="brand" rules={[{
                    required: true,
                    // message: 'Please input brand!'
                }]}>
                    <Select>
                        {props.brands.brands.map((brand:any)=>{
                            return brand.isActive === "1" ? <Select.Option key={brand.brandID} value={brand.brandID} >{brand.name}</Select.Option> : null;
                        })}

                        {/*<Select.Option value="trd">TRD</Select.Option>*/}
                        {/*<Select.Option value="42">42</Select.Option>*/}
                        {/*<Select.Option value="bern">Bern</Select.Option>*/}
                    </Select>


                </Form.Item>
                <Form.Item label={t('Country')} name="country" rules={[{
                    required: true,
                }]}>
                    <Select>
                        {props.countries.countries.map((country:any)=>{
                            return country.isActive === "1" ? <Select.Option key={country.countryID} value={country.countryID} >{country.name}</Select.Option> : null;
                        })}


                        {/*<Select.Option value="Germany">Germany</Select.Option>*/}
                        {/*<Select.Option value="United Kingdom">United Kingdom</Select.Option>*/}
                        {/*<Select.Option value="Poland">Poland</Select.Option>*/}
                    </Select>


                </Form.Item>

                <Form.Item label={t('Assigned To')} name="assignedToID"
                           rules={[{
                               required: true,
                               // message: 'Please input supervisorID!'
                           }]}>
                    <TreeSelect
                        treeData={[
                            {title: 'none', value: '0', children: []},
                            {title: 'SuperAdmin', value: '1', children: [{title: 'Admin', value: '2'}]},
                            {title: 'Roman', value: '8', children: [{title: 'Tom', value: '9'}]},
                            {
                                title: 'Anita',
                                value: '5',
                                children: [{title: 'Bill', value: '6'}, {title: 'Gabby', value: '7'},]
                            },
                            {title: 'Test', value: '17', children: []},
                            {title: 'Daniel', value: '3', children: [{title: 'Alex', value: '4'}]},
                        ]}
                    />
                </Form.Item>

                <Form.Item  {...tailLayout}
                    // wrapperCol={{ offset: 8, span: 16 }}
                >

                    <Space>
                        <Button type="primary" htmlType="submit" loading={props.clients.loading} disabled={props.clients.loading}>
                            {t('Save')}
                        </Button>

                        <Button htmlType="button" onClick={onReset} loading={props.clients.loading} disabled={props.clients.loading}>
                            {t('Reset')}
                        </Button>
                    </Space>
                </Form.Item>

            </Form>
        </div>
}

export default EditClient