import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import ClearingCompanies from "./ClearingCompanies";
import {setLoading} from "../../redux/clearingCompaniesReducer";
// import {setLoading as clientsSetLoading} from "../../redux/clearingCompaniesReducer";
import {getClearingCompanies} from "../../redux/clearingCompaniesReducer";

const ClearingCompaniesContainer : React.FC = (props) => {
    return (
        <ClearingCompanies {...props}/>
    );
};

let mapStateToProps:any = (state:any) => {
    return {
        auth: state.auth,
        // users: state.users,
        clearingCompanies: state.clearingCompanies,
    }
};

export default compose(connect(mapStateToProps,{setLoading,getClearingCompanies}))(ClearingCompaniesContainer);
