// import React from 'react';
// import {Skeleton} from "antd";
//
// const Users = () => {
//     return (
//         <div>
//             {/*<h1>Users Page</h1>*/}
//             {/*<p>Users will be here soon...</p>*/}
//             <h1>Users Page</h1>
//             <p>(список пользователей CRM будет доступен только тимам и админам)</p>
//             <Skeleton />
//         </div>
//     );
// };

import React, { useEffect, useState } from 'react';
import type { GetProp, TableProps } from 'antd';
import {Button, Input, message, Popconfirm, PopconfirmProps, Table} from 'antd';
import qs from 'qs';
import {SorterResult} from "antd/lib/table/interface";
import {getUsers, setLoading} from "../../redux/usersReducer";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import css from "../Campaigns/Campaigns.module.css";
import {setLoading as clientsSetLoading} from "../../redux/clientsReducer";
import {getCampaigns} from "../../redux/campaignsReducer";

type ColumnsType<T extends object = object> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
    clientCount: number;
    campaignID: number;
    title: string;
    affID: number;
    isActive: number,
    // lastName: string;
    // gender: string;
    // dob: {
    //     age: number;
    //     date: string;
    //
    // }
    // createDate: number,
    // desk: string,
    // login: {
    //     uuid: string;
    // };
}

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: SorterResult<any>['field'];
    sortOrder?: SorterResult<any>['order'];
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}


// const getRandomuserParams = (params: TableParams) => ({
//     results: params.pagination?.pageSize,
//     page: params.pagination?.current,
//     ...params,
// });

const Campaigns: React.FC = (props: any) => {

    const { t } = useTranslation('',{ keyPrefix: 'campaigns' });
    const navigate = useNavigate();

    // const [data, setData] = useState<DataType[]>();
    // const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [dataSource, setDataSource] = useState(props.campaigns.campaigns);
    const [value, setValue] = useState('');

    useEffect(()=>{
        if(props.campaigns.campaigns.length === 0){
            props.setLoading(true);
            props.getCampaigns();
        }

    },[])

    useEffect(()=>{
        setDataSource(props.campaigns.campaigns);
    },[props.campaigns.campaigns])

    // const FilterByNameInput = (
    //     <Input
    //         placeholder="Search Name"
    //         value={value}
    //         onChange={e => {
    //             const currValue = e.target.value;
    //             setValue(currValue);
    //             const filteredData = props.users.users.filter((entry:any) =>
    //                 // fullName = entry.firstName + ' ' + entry.lastName;
    //                 entry.fullName.toLowerCase().includes(currValue.toLowerCase())
    //             );
    //             setDataSource(filteredData);
    //             // todo - change to filtering on server
    //         }}
    //     />
    // );


    const [arrParams, setArrParams] = useState({});

    let getFilteredClients = (campaignID:number) => {
        console.log(campaignID)
        // set 'loading' for Clients
        props.clientsSetLoading(true)
        // send request to API to get filtered clients
        let tempArr:any = arrParams;
        tempArr['campaignID'] = campaignID
        setArrParams(tempArr)
        props.getClients(arrParams);
        // switch to Clients
        navigate('/clients');
    }


    let remove = (campID:number) => {
        // todo add confirmation yes/no window
        props.removeCamp(campID);
    }


    const cancel: PopconfirmProps['onCancel'] = (e) => {
        console.log(e);
        message.error('Click on No');
    };


    const columns: ColumnsType<DataType> = [
        {
            title: t('campaignID'),
            dataIndex: 'campaignID',
            width: '10%'
        },
        {
            title: t('campaignTitle'),
            dataIndex: 'campaignTitle',
        },
        {
            title: t('affTitle'),
            dataIndex: 'affTitle',
        },

        {
            title: t('clientCount'),
            dataIndex: 'clientCount',
            // sorter: (a,b) => (a.email).localeCompare(b.email),
            render: (_,d) => <div className={css.link} onClick={()=>getFilteredClients(d.campaignID)}>{d.clientCount} [{t('See Clients')}]</div>,
        },

        {
            title: t('Action'),
            key: 'operation',
            fixed: 'right',
            width: 100,
            // render: () => <a>action</a>,
            render: (_,d) => <div className={css.link}
                // onClick={()=>remove(d.affID)}
            >
                <Popconfirm
                    title="Delete campaign"
                    description="Are you sure to delete this campaign?"
                    onConfirm={()=>remove(d.campaignID)}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button danger>{t('remove')}</Button>
                </Popconfirm>
            </div>,
        },
    ];
    const fetchData = () => {
        // setLoading(true);
        // fetch(`https://randomuser.me/api?${qs.stringify(getRandomuserParams(tableParams))}`)
        //     .then((res) => res.json())
        //     .then(({ results }) => {
        //         // setData(results);
        //         setLoading(false);
        //         setTableParams({
        //             ...tableParams,
        //             pagination: {
        //                 ...tableParams.pagination,
        //                 total: 200,
        //                 // 200 is mock data, you should read it from server
        //                 // total: data.totalCount,
        //             },
        //         });
        //     });
    };

    useEffect(() => {
        fetchData();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        JSON.stringify(tableParams.filters),
    ]);

    const handleTableChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
            sortField: Array.isArray(sorter) ? undefined : sorter.field,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // setData([]);
        }
    };

    return (
        <>
            <h1>{t("Campaigns List")}</h1>

                <Link to="/addCampaign" className={css.add}>{t('Add Campaign')}</Link>
            <Table
                columns={columns}
                rowKey={(record) => record.campaignID}
                dataSource={dataSource}
                pagination={tableParams.pagination}
                loading={props.campaigns.loading}
                onChange={handleTableChange}
                bordered
                size="small"
                rowSelection={{
                }}
            />
        </>

    );
};

export default Campaigns;
