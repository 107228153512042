import React, {useState} from 'react';
import {Breadcrumb, Button, Layout, Menu, theme} from 'antd';
import SideMenuContainer from "../SideMenu/SideMenuContainer";
import {Link, Route, Routes} from "react-router-dom";
import Home from "../Home/Home";
import LoginContainer from "../LoginContainer/LoginContainer";
import SignUp from "../SignUp/SignUp";
import Clients from "../Clients/Clients";
import UsersContainer from "../Users/UsersContainer";
import AddUserContainer from "../AddUser/AddUserContainer";
import logo from '../../imgs/myCRM_logo.png'
import css from './Main.module.css'
import {compose} from "redux";
import {connect} from "react-redux";
import {setAuthData, setLoading} from "../../redux/authReducer";
import ClientsContainer from '../Clients/ClientsContainer';
import AddClient from "../AddClient/AddClient";
import AddClientContainer from "../AddClient/AddClientContainer";
import AddCampaignContainer from "../AddCampaign/AddCampaignContainer";
import CampaignsContainer from "../Campaigns/CampaignsContainer";
import ClientViewContainer from "../ClientView/ClientViewContainer";
import AffiliatesContainer from "../Affiliates/AffiliatesContainer";
import ClearingCompaniesContainer from '../ClearingCompanies/ClearingCompaniesContainer';
import AddAffiliate from "../AddAffiliate/AddAffiliate";
import AddAffiliateContainer from "../AddAffiliate/AddAffiliateContainer";
import {LeftCircleOutlined, MenuFoldOutlined, MenuUnfoldOutlined, RightCircleOutlined} from "@ant-design/icons";
import EditClientContainer from "../EditClient/EditClientContainer";
import UserHierarchyContainer from "../UserHierarchy/UserHierarchyContainer";

const { Header, Content, Footer, Sider } = Layout;

const Main = (props:any) => {
    const [collapsed, setCollapsed] = useState(false);
    let toggleCollapsed = () => {
        setCollapsed(!collapsed)
    }
    return (

        // <Row>
        //     <Col span={4} className={"sideMenu"}>
        //
        //     </Col>
        //     <Col span={19}>
        //     </Col>
        // </Row>


    <Layout style={{ minHeight: "100vh" }}>
        <Header style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="" className={css.mainLogo}/>
            <div className="demo-logo" />
            {/*<Menu*/}
            {/*    theme="dark"*/}
            {/*    mode="horizontal"*/}
            {/*    defaultSelectedKeys={['2']}*/}
            {/*    items={items1}*/}
            {/*    style={{ flex: 1, minWidth: 0 }}*/}
            {/*/>*/}
        </Header>



        {/*<Content style={{  }}>*/}
            {/*<Breadcrumb style={{ margin: '16px 0' }}>*/}
            {/*    <Breadcrumb.Item>Home</Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Item>List</Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Item>App</Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}
            <Layout
                style={{   }}
            >

                {/*<Button type="primary" onClick={toggleCollapsed} className={css.collapseButton}>*/}
                {/*    {collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />}*/}
                {/*</Button>*/}
                <Sider  className={css.sider}
                        // width={props.auth.authStatus ? undefined : 0}
                        // trigger={collapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />}
                       collapsible
                       collapsed={collapsed}
                       onCollapse={setCollapsed}
                       width={200}
                       style={{
                           boxShadow: "2px 0 8px rgba(0, 0, 0, 0.1)",
                           overflow: "auto",
                           height: "100vh",
                           position: "fixed",
                           left: 0,
                           top: 70, // Учитываем высоту хедера
                           bottom: 0,
                       }}
                       breakpoint="lg"
                       collapsedWidth={30}
                       // collapsible
                >
                    <SideMenuContainer/>
                </Sider>
                <Layout
                    style={{ padding: '6px',
                        marginLeft: collapsed ? 30 : 200, // Учитываем ширину меню
                        transition: "margin 0.2s",}}

                >
                    <Content
                        style={{
                            padding: "4px",
                            margin: "6px",
                            minHeight: "85vh",
                            // borderRadius: 8,
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                            overflow: "hidden",
                        }}>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/login" element={<LoginContainer/>}/>
                            <Route path="/signup" element={<SignUp/>}/>
                            <Route path="/clients" element={<ClientsContainer/>}/>
                            <Route path="/addClient" element={<AddClientContainer/>}/>
                            <Route path="/users" element={<UsersContainer/>}/>
                            <Route path="/addUser" element={<AddUserContainer/>}/>
                            <Route path="/addCampaign" element={<AddCampaignContainer/>}/>
                            <Route path="/campaigns" element={<CampaignsContainer/>}/>
                            <Route path="/brands" element={<CampaignsContainer/>}/>
                            <Route path="/desks" element={<CampaignsContainer/>}/>
                            <Route path="/affiliates" element={<AffiliatesContainer/>}/>
                            <Route path="/client/:id" element={<ClientViewContainer/>}/>
                            <Route path="/cc" element={<ClearingCompaniesContainer/>}/>
                            <Route path="/addAff" element={<AddAffiliateContainer/>}/>
                            <Route path="/editClient/:id" element={<EditClientContainer/>}/>
                            <Route path="/usersHierarchy" element={<UserHierarchyContainer/>}/>
                        </Routes>
                    </Content>

                </Layout>
            </Layout>
        {/*</Content>*/}
        <Footer style={{ textAlign: 'center' }}>
            ©myCRM <br/>
            = 2024 - {new Date().getFullYear()} = <br/>
            Created by Andrew aka <Link to="https://t.me/iamadmin111">@iamadmin111</Link>
        </Footer>
    </Layout>

    );
};




export default Main;
