import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import AddCampaign from "./AddCampaign";
import {setLoading, setNewClientAddedStatus, addClient} from "../../redux/clientsReducer";
import {addCampaign, getCampaigns, setNewCampaignAdded} from "../../redux/campaignsReducer";

const AddCampaignContainer : React.FC = (props) => {

    return (
        <AddCampaign {...props}/>
    );
};

let mapStateToProps:any = (state:any) => {
    return {
        affs: state.affs,
        campaigns: state.campaigns,
    }
};

export default compose(connect(mapStateToProps,{addCampaign,setLoading,setNewCampaignAdded,getCampaigns}))(AddCampaignContainer);
