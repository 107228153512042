
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Checkbox, GetProp, Layout, Popover, TableProps} from 'antd';
import {Input, Select, Table} from 'antd';
import qs from 'qs';
import {SorterResult} from "antd/lib/table/interface";
import {getUsers, setLoading} from "../../redux/usersReducer";
import {useTranslation} from "react-i18next";
import {Link,useNavigate} from "react-router-dom";
import {
    CaretDownOutlined,
    CaretUpOutlined, DeleteOutlined, EditOutlined, FilterFilled,
    SearchOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined
} from "@ant-design/icons";
import css from "../Clients/Clients.module.css";
import { createStyles } from 'antd-style';
import {changeStatus, getClients} from "../../redux/clientsReducer";
import {debug} from "util";
import {Content} from "antd/lib/layout/layout";

import type { CheckboxProps } from 'antd';
import {CheckboxChangeEvent} from "antd/lib/checkbox";

type ColumnsType<T extends object = object> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
    statusID: number;
    fullName: string;
    // lastName: string;

    // gender: string;
    email: string;
    // dob: {
    //     age: number;
    //     date: string;
    //
    // }
    clientID: number,
    createDate: number,
    deskID: string,
    phone: string,
    password: string,
    isActive: string,
    isOnline: string,
    lastLogInDate: number,
    lastActionDate: number,
    lastAction: string,
    typeID: string,
    brandID: string,
    assignedToID: string,
    countryID: string,
    campaignID: string,
    utm_campaign: string,
    ip: string,
    comment: string,
    additional_info: string,
    lastCommentDate: number,
    // login: {
    //     uuid: string;
    // };
}

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: SorterResult<any>['field'];
    sortOrder?: SorterResult<any>['order'];
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}


// const getRandomuserParams = (params: TableParams) => ({
//     results: params.pagination?.pageSize,
//     page: params.pagination?.current,
//     ...params,
// });

const Clients: React.FC = (props: any) => {

    const navigate = useNavigate();

    const { t } = useTranslation('',{ keyPrefix: 'clients' });

    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    // one time load data from server
    useEffect(()=>{

        if (props.clients.count === 0) {
            props.setLoading(true);
            // console.log(1)
            props.getClients([],100);
        }

    },[])

    // if store updates
    useEffect(()=>{
        setDataSource(props.clients.clients);
    },[props.clients.clients])
    useEffect(()=>{
        setFilteredStatuses(props.statuses.statuses);
    },[props.statuses.statuses])

    // main dataSource
    const [dataSource, setDataSource] = useState(props.clients.clients);
    const [filteredStatuses, setFilteredStatuses] = useState(props.statuses.statuses);
    //////////////////////////////////////////////   for filtering ----- start  //////////////////////////////////////////////

    const [arrParams, setArrParams] = useState({});

    const [valueName, setValueName] = useState('');
    const [valuePhone, setValuePhone] = useState('');
    const [valueEmail, setValueEmail] = useState('');

    const [showSearchBarName,setSSSName] = useState(false);
    const [showFilterStatus,setFStatus] = useState(false);
    const [showSearchBarPhone,setSSSPhone] = useState(false);
    const [showSearchBarEmail,setSSSEmail] = useState(false);
    const [sortByNamesMode,setSortByNamesMode] = useState('none');

    let showSearchName = () => {
        setSSSName(true);
    }

    let showFilterByStatus = () => {
        setFStatus(true);
    }

    let showSearchPhone = () => {
        setSSSPhone(true);
    }
    let showSearchEmail = () => {
        setSSSEmail(true);
    }

    let sortByNames = (sortByNamesMode: string) => {
        if(sortByNamesMode === 'none'){
            setSortByNamesMode('ASC');
        }else if(sortByNamesMode === 'ASC'){
            setSortByNamesMode('DESC');
        }else if(sortByNamesMode === 'DESC'){
            setSortByNamesMode('none');
        }
    }


    const FilterByNameInput = (
        <div className={css.tableTitle}>
            <Input
                style={!showSearchBarName ? { display: 'none' } : {}}
                placeholder="Search Name"
                value={valueName}
                onChange={e => {
                    const currValue = e.target.value;
                    let tempArr:any = arrParams;
                    tempArr['fullName'] = currValue
                    setArrParams(tempArr)
                    setValueName(currValue);
                    timer(timerHandler);
                }}
            />
            <span
                style={showSearchBarName ? { display: 'none' } : {}}
                className={css.title}> <SearchOutlined
                className={css.search_icon} onClick={()=>showSearchName()}/></span>

            <span className={css.sorting_wrapper} onClick={()=>sortByNames(sortByNamesMode)}>
                        <CaretUpOutlined className={sortByNamesMode === 'ASC' ? css.active : undefined}/>
                        <CaretDownOutlined className={sortByNamesMode === 'DESC' ? css.active : undefined}/>
                    </span>

            &nbsp; <FilterFilled />

        </div>

    );

    const FilterByPhoneInput = (
        <>
            <Input
                style={!showSearchBarPhone ? { display: 'none' } : {}}
                placeholder="Search Phone"
                value={valuePhone}
                onChange={e => {
                    const currValue = e.target.value;
                    let tempArr:any = arrParams;
                    tempArr['phone'] = currValue
                    setArrParams(tempArr)
                    setValuePhone(currValue);
                    timer(timerHandler);
                }}
            />
            <div
                style={showSearchBarPhone ? { display: 'none' } : {}}
                className={css.title}> <SearchOutlined
                className={css.search_icon} onClick={()=>showSearchPhone()}/></div>
        </>

    );

    const FilterByEmailInput = (
        <>
            <Input
                style={!showSearchBarEmail ? { display: 'none' } : {}}
                placeholder="Search Email"
                value={valueEmail}
                onChange={e => {
                    const currValue = e.target.value;
                    let tempArr:any = arrParams;
                    tempArr['email'] = currValue
                    setArrParams(tempArr)
                    setValueEmail(currValue);
                    timer(timerHandler);
                }}
            />
            <div
                style={showSearchBarEmail ? { display: 'none' } : {}}
                className={css.title}> <SearchOutlined
                className={css.search_icon} onClick={()=>showSearchEmail()}/></div>
        </>

    );
    // const onChange: CheckboxProps['onChange'] = (e) => {
    //     console.log(`checked = ${e.target.checked}`);
    // };

    // const chooseStatusesForFilter:CheckboxProps['onChange'] = (e) => {
    //
    //     console.log(`checked = ${e.target.checked}`);
    //     console.log(e.target.value)
    //             // let tempArr:any = arrParams;
    //             // tempArr['statuses'][statusID] = statusID
    //             // setArrParams(tempArr)
    // }


    const [selectedStatuses, setSelectedStatuses] = useState<number[]>([]);
    const chooseStatusesForFilter = (e: CheckboxChangeEvent, statusID: number) => {
        if (e.target.checked) {
            // Добавляем выбранный статус в массив
            setSelectedStatuses([...selectedStatuses, statusID]);
        } else {
            // Убираем статус из массива
            setSelectedStatuses(selectedStatuses.filter((id) => id !== statusID));
        }
        // console.log(selectedStatuses);

    };
    let [firstLoad,setFirstLoad] = useState(true);
    // adding filtered statuses to filtering parameters for new query to DB
    useEffect(()=>{
        if(firstLoad){
            setFirstLoad(false)
        }else{
            let tempArr:any = arrParams;
            tempArr['statuses'] = selectedStatuses
            setArrParams(tempArr)
            timer(timerHandler);
            // console.log(5)
            // console.log(selectedStatuses)
        }

    },[selectedStatuses])



    const FilterByStatus = (
        <div className={css.tableTitle}>
            {/*<Input*/}
            {/*    style={!showSearchBarName ? { display: 'none' } : {}}*/}
            {/*    placeholder="Search Name"*/}
            {/*    value={valueName}*/}
            {/*    onChange={e => {*/}
            {/*        const currValue = e.target.value;*/}
            {/*        let tempArr:any = arrParams;*/}
            {/*        tempArr['fullName'] = currValue*/}
            {/*        setArrParams(tempArr)*/}
            {/*        setValueName(currValue);*/}
            {/*        timer(timerHandler);*/}
            {/*    }}*/}
            {/*/>*/}
            {/*<span*/}
            {/*    style={showSearchBarName ? { display: 'none' } : {}}*/}
            {/*    className={css.title}> <SearchOutlined*/}
            {/*    className={css.search_icon} onClick={()=>showSearchName()}/></span>*/}

            &nbsp; <FilterFilled style={showFilterStatus ? { display: 'none' } : {}} onClick={()=>showFilterByStatus()}/>
            <div style={!showFilterStatus ? { display: 'none' } : {}}>
                <Input
                    type="text"
                    placeholder="Search status"
                        onChange={e => {
                            const currValue = e.target.value;
                            setFilteredStatuses(props.statuses.statuses.filter((status: any) => status.name.toLowerCase().includes(currValue.toLowerCase()) ))
                            // setFilteredStatuses(filteredStatuses.find((status: any) => status.name === currValue))
                            // let tempArr:any = arrParams;
                            // tempArr['status'] = currValue
                            // setArrParams(tempArr)
                            // setValueName(currValue);
                            // timer(timerHandler);
                        }}
                />
                {/*{filteredStatuses.map((s: any)=>{*/}
                {/*    return <div key={s.statusID}><Checkbox onChange={chooseStatusesForFilter} value={s.statusID}>{s.name}</Checkbox></div>*/}
                {/*})}*/}

                {filteredStatuses.map((s: any) => (
                    s.isActive === "1" ?
                    <div key={s.statusID}>
                        <Checkbox
                            onChange={(e) => chooseStatusesForFilter(e, s.statusID)} // Передаём ID чекбокса
                            checked={selectedStatuses.includes(s.statusID)} // Проверяем, выбран ли чекбокс
                        >
                            {s.name}
                        </Checkbox>
                    </div> : null
                ))}

            </div>
        </div>

    );


    // timer
    const timerHandler = useRef(null);
    let timer = (timerHandler:any) => {
        clearTimeout(timerHandler.currTimer);
        timerHandler.currTimer = setTimeout(()=>{
            // console.log(3)
            props.getClients(arrParams);
        },2000);
    }

    // ESC
    useEffect(() => {
        const handleEsc = (event: any) => {
            if (event.key === 'Escape') {
                setSSSName(false);
                setValueName('');
                setSSSPhone(false);
                setValuePhone('');
                setSSSEmail(false);
                setValueEmail('');
                setArrParams({});
                setFStatus(false);
                // setDataSource(props.clients.clients);
                // console.log(4)
                // console.log(event.key)
                props.getClients();
            }
        };
        document.addEventListener('keydown', handleEsc);
        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, []);

    //////////////////////////////////////////////   for filtering ----- end  //////////////////////////////////////////////

    const title = '';


    // const content = (
    //     <div>
    //         <span>Content 1</span> <span>Content 2</span>
    //     </div>
    // );

    let statusChanged = (value: any,clientID: number) => {
        props.changeStatus(clientID,value.value)
    }

    let edit = (clientID:number) => {
        // console.log(clientID)
        navigate('/editClient/'+clientID)
    }

    let del = (clientID:number) => {
        console.log(clientID)
        // navigate('/editClient/'+clientID)
    }

    const columns: ColumnsType<DataType> = [
        {
            title:

                <Popover placement="bottomLeft" title={title} content={FilterByNameInput} className={css.popover}>
                    <div>{t('Name')}</div>
                </Popover>

                ,
            dataIndex: 'fullName',
            // sorter: (a,b) => (a.firstName+a.lastName).localeCompare(b.firstName+b.lastName),
            render: (text, record) => (

                <Link to={`/client/${record.clientID}`} className={css.link}>{record.fullName}</Link>
            ),
            // width: '20%',
            width: 'auto',
            fixed: 'left',
            key: 'fullName'
        },
        {
            title:
                <Popover placement="bottomLeft" title={title} content={FilterByPhoneInput}>
                    <div>{t('Phone')}</div>
            </Popover>,
            dataIndex: 'phone',
            // filters: [
            //     { text: 'Poland', value: 'poland' },
            //     { text: 'Spain', value: 'spain' },
            // ],

            // sorter: {
            //     compare: (a,b) => (a.phone).localeCompare(b.phone),
            //     multiple: 2,
            // },
            width: 'auto',
            fixed: 'left',
            key: 'phone'
        },

        {
            title:
                <Popover placement="bottomLeft" title={title} content={FilterByEmailInput}>
                    <div>{t('Email')}</div>
                </Popover>,

            dataIndex: 'email',
            // sorter: (a,b) => (a.email).localeCompare(b.email),
            width: 'auto',
            fixed: 'left',
            key: 'email'
        },

        {
            title: t('Desk'),
            dataIndex: 'desk',
            // filters: [
            //     { text: 'Poland', value: 'poland' },
            //     { text: 'Spain', value: 'spain' },
            // ],
            // sorter: {
            //     compare: (a,b) => (a.desk).localeCompare(b.desk),
            //     multiple: 2,
            // },
            showSorterTooltip: {
                target: 'sorter-icon'
            },
            width: 'auto',

            render: (_, d) => {

                const desk = props.desks.desks.find(
                    (desk: any) => desk.deskID === d.deskID
                );
                return desk ? <div key={desk.deskID}>{desk.name}</div> : null;
            },
            key: 'desk'
        },
        { title:
                <Popover placement="bottomLeft" title={title} content={FilterByStatus}>
                    <div>{t('Status')}</div>
                </Popover>,
            dataIndex: 'status', key: 'status', width: '150px',
            // sorter: (a,b) => (a.status).localeCompare(b.status),
            render: (_, d) => {
                    // console.log(d)
                const status = props.statuses.statuses.find(
                    (status: any) => status.statusID === d.statusID
                );

                    let statuses_arr: any = [];

                {props.statuses.statuses.map((st:any,k:number)=>{
                    return st.isActive === '1' ?
                        statuses_arr.push({
                            value: st.statusID,
                            label: <div key={st.statusID} style={{  backgroundColor: st.bgСolor !== '' ? st.bgСolor : 'transparent' , color: st.color, textAlign: "center", borderRadius: 5}}>{st.name}</div> ,
                        })
                         : null;
                })}
                // console.log(statuses_arr)
                return status ?


                    <Select labelInValue style={{minWidth: 200}} defaultValue={status.statusID}
                            options={statuses_arr} variant="borderless"
                            onChange={(v)=>statusChanged(v,d.clientID)}
                    >

                    </Select>


                    // <div key={status.statusID} style={{ backgroundColor: status.bgСolor, color: status.color, textAlign: "center"}}>{status.name}</div>

            : null;


            },
        },
        {
            title: t('Create date'),
            dataIndex: 'createDate',
            // filters: [
            // ],
            // defaultSortOrder: 'descend',

            // sorter: {
            //     compare: (a, b) => a.createDate - b.createDate,
            //     multiple: 2,
            // },
            // sorter: true,
            width: 'auto',
            render: (_,d) => Intl.DateTimeFormat('ru',{timeStyle:'medium',dateStyle:'short'}).format(d.createDate*1000),
            key: 'createDate'
        },
        // {
        //     title: 'Age',
        //     dataIndex: 'dob',
        //     filters: [
        //     ],
        //     sorter: true,
        //     width: '20%',
        //     render: (a) => a.age,
        // },

        { title: t('Password'), dataIndex: 'password', key: 'password',
            width: 'auto',
            render: (_,d) => d.password,
        },
        { title: t('Active'), dataIndex: 'isActive', key: 'isActive',
            width: '150px',
            render: (_,d) => d.isActive === '1' ? 'yes' : 'no', },
        { title: t('Online'), dataIndex: 'isOnline', key: 'isOnline',
            width: '150px',
            render: (_,d) => d.isOnline === '1' ? 'yes' : 'no', },
        { title: t('Last LogIn Date'), dataIndex: 'lastLogInDate', key: 'lastLogInDate', width: '150px',
            render: (_,d) => d.lastLogInDate != 0 ? (new Date(d.lastLogInDate*1000).toUTCString()) : ''  },
        { title: t('Last Action Date'), dataIndex: 'lastActionDate', key: 'lastActionDate', width: '150px',
            render: (_,d) => d.lastActionDate != 0 ? Intl.DateTimeFormat('ru',{timeStyle:'medium',dateStyle:'short'}).format(d.lastActionDate*1000) : ''
        },
        { title: t('Last Action'), dataIndex: 'lastAction', key: 'lastAction', width: '150px' },
        { title: t('Type'), dataIndex: 'type', key: 'type', width: '150px',
        render: (_,d) => d.typeID === '1' ? 'Lead' : 'Client'},
        { title: t('Brand'), dataIndex: 'brand', key: 'brand', width: '150px',
            render: (_, d) => {
                const brand = props.brands.brands.find(
                    (brand: any) => brand.brandID === d.brandID
                );
                return brand ? <div key={brand.brandID}>{brand.name}</div> : null;
            },
        },
        { title: t('Assigned To'), dataIndex: 'assignedToID', key: 'assignedToID', width: '150px',

            render: (_, d) => {
                const user = props.users.users.find(
                    (user: any) => user.userID === d.assignedToID
                );
                return user ? <div key={user.userID}>{user.fullName}</div> : d.assignedToID;
            },
        },

        { title: t('Country'), dataIndex: 'country', key: 'country', width: '150px',

            render: (_, d) => {
                const country = props.countries.countries.find(
                    (country: any) => country.countryID === d.countryID
                );
                return country ? <div key={country.countryID}>{country.name}</div> : null;
            },
        },
        { title: t('Campaign'), dataIndex: 'campaignID', key: 'campaignID', width: '150px',

            render: (_, d) => {
                const campaign = props.campaigns.campaigns.find(
                    (campaign: any) => {
                        // console.log(campaign)
                        return campaign.campaignID === d.campaignID
                    }
                );
                return campaign ? <div key={campaign.campaignID}>{campaign.campaignTitle}</div> : d.campaignID;
            },
        },
        { title: t('Utm campaign'), dataIndex: 'utmCampaign', key: 'utmCampaign', width: '150px' },
        { title: t('IP'), dataIndex: 'ip', key: 'ip', width: '150px' },
        { title: t('Comment'), dataIndex: 'comment', key: 'comment', width: '150px' },
        { title: t('Additional info'), dataIndex: 'additionalInfo', key: 'additionalInfo', width: '150px' },
        { title: t('Last Comment Date'), dataIndex: 'lastCommentDate', key: 'lastCommentDate', width: '150px',
            render: (_,d) => d.lastCommentDate != 0 ? (new Date(d.lastCommentDate*1000).toUTCString()) : '' },

        {
            title: t('Action'),
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (_,d) => <>
                <span className={css.edit} onClick={()=>edit(d.clientID)} title={t('edit')}><EditOutlined /></span>
                <span className={css.delete} onClick={()=>del(d.clientID)} title={t('delete')}><DeleteOutlined /></span>
            </>,
        },
    ];
    const fetchData = () => {
    };

    useEffect(() => {
        fetchData();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        JSON.stringify(tableParams.filters),
    ]);

    const handleTableChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
            sortField: Array.isArray(sorter) ? undefined : sorter.field,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // setData([]);
        }
    };

    // const { styles } = useStyle();
    return (
        <>
            <h1>{t("Clients List")}</h1>
            <Link to="/addClient" className={css.add}>{t('addClient')}</Link>
            <Table
                className={css.table}

                columns={columns}
                rowKey={(record) => record.clientID}
                dataSource={dataSource}
                pagination={tableParams.pagination}
                loading={props.clients.loading}
                onChange={handleTableChange}
                bordered
                size="small"
                rowSelection={{
                }}

                scroll={{ x: 'max-content', y: 105 * 5 }}
            />
        </>

    );
};

export default Clients;
