//
// let initialState = {}
//
// const statusesReducer = (state = initialState, action:any) => {
//     switch (action.type) {
//
//         default:
//             return state;
//     }
// }
//
// export default statusesReducer;
//

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import {API} from "../api/API";
import {getAuthStatus} from "./authReducer";

const SET_NEW_STATUS_ADDED_STATUS = 'crm/clearingCompanies/SET_NEW_STATUS_ADDED_STATUS';
const SET_NEW_STATUS_ADDED_ERROR = 'crm/clearingCompanies/SET_NEW_STATUS_ADDED_ERROR';
const SET_NEW_STATUS_ADDED_ID = 'crm/clearingCompanies/SET_NEW_STATUS_ADDED_ID';
const SET_LOADING = 'crm/clearingCompanies/SET_LOADING';
const SET_CLEARING_COMPANIES = 'crm/clearingCompanies/SET_CLEARING_COMPANIES';

type ClearingCompany = {
    id: number | null,
    clearingCompany: string | null,
    active: number | null,
    comment: string | null,
}
type SelectedStatus = {
    id: number | null,
    status: string | null,
    active: boolean | null,
    comment: string | null,
}

let initialState = {
    clearingCompanies: []  as Array<ClearingCompany>,
    // selectedStatus: {
    //     id: null,
    //     status: null,
    //     active: null,
    //     comment: null,
    // } as SelectedStatus,
    status: null as string | null,
    active: true as boolean | null,
    comment: null as string | null,
    // newStatusAddedStatus: false,
    // newStatusAddedError: false,
    // newStatusAddedErrorText: '' as string,
    // newStatusAddedId: null as null | number,
    loading: false as boolean | null

}

const clearingCompaniesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        // case SET_NEW_CLIENT_ADDED_STATUS:
        //     return {
        //         ...state,
        //         newClientAddedStatus: action.status
        //     };
        // case SET_NEW_CLIENT_ADDED_ERROR:
        //     return {
        //         ...state,
        //         newClientAddedError: action.error,
        //         newClientAddedErrorText: action.errorText
        //     };
        // case SET_NEW_CLIENT_ADDED_ID:
        //     return {
        //         ...state,
        //         newClientAddedId: action.newClientAddedId
        //     };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading,
            };
        case SET_CLEARING_COMPANIES:
            return {
                ...state,
                clearingCompanies: action.clearingCompanies,
            };
        default:
            return state;
    }
}

const setLoadingAC = (loading: boolean) => (
    {
        type: SET_LOADING,
        loading: loading,
    }
)

export const setClearingCompaniesAC = (clearingCompanies: any) => (
    {
        type: SET_CLEARING_COMPANIES,
        clearingCompanies: clearingCompanies,
    }
)

// const setNewClientAddedStatusAC = (status: boolean) => (
//     {
//         type: SET_NEW_CLIENT_ADDED_STATUS,
//         status: status
//     }
// )
//
// const setNewClientAddedIdAC = (newClientAddedId: number) => (
//     {
//         type: SET_NEW_CLIENT_ADDED_ID,
//         newClientAddedId: newClientAddedId
//     }
// )
//
// const setNewClientAddedErrorAC = (error: boolean, errorText: string) => (
//     {
//         type: SET_NEW_CLIENT_ADDED_ERROR,
//         error: error,
//         errorText: errorText
//     }
// )

// thunks

export const getClearingCompanies = () => async (dispatch: any) => {

    await API.getClearingCompanies()
        .then((data)=>{
            if(data.result === 'ok'){
                dispatch(setClearingCompaniesAC(JSON.parse(data.clearingCompanies)));
            }else{
                console.log(data);
            }
            dispatch(setLoading(false));

        })
        .catch((error)=>{
            console.error('error: ' + error)
            dispatch(setLoading(false));
            dispatch(getAuthStatus())
        });
}

export const setLoading = (loading: boolean) => (dispatch: any) => {
    // debugger
    dispatch(setLoadingAC(loading))
}
/*


export const setNewClientData = (email: string | undefined,password: string | undefined,fullName: string | undefined,isActive: boolean | undefined,isOnline: boolean | undefined,lastLogInDate: number | undefined,lastActionDate: number | undefined,lastAction: string | undefined,status: string | undefined,type: string | undefined,status: string | undefined,createDate: number | undefined,createdBy: string | undefined,assignedToID: number | undefined,phone: string | undefined,address: string | undefined,status: string | undefined,lastCommentDate: number | undefined,country: string | undefined,campaignID: number | undefined) => async (dispatch: any) => {
    // dispatch(setAuthDataAC(login, pass, rememberMe))
    await API.addClient(email,password,fullName,isActive,isOnline,lastLogInDate,lastActionDate,lastAction,status,type,status,createDate,createdBy,assignedToID,phone,address,status,lastCommentDate,country,campaignID)
        .then((data)=>{
            if(data.result === 'ok' && data.clientAdded === true){
                dispatch(setNewClientAddedStatusAC(true));
                dispatch(setNewClientAddedIdAC(data.userId));
                dispatch(setNewClientAddedErrorAC(false,''));
                dispatch(setLoadingAC(false));
            }else{
                dispatch(setNewClientAddedErrorAC(true,data.error));
                dispatch(setNewClientAddedStatusAC(false));
                dispatch(setLoadingAC(false));
                // debugger
            }
            console.log(data);
            // dispatch(setAuthStatusAC(data.status === 'logged in' ));
        })
        .catch((error)=>{
            console.error('error: ' + error)
        });

    // API - auth
    // if ok - set auth data null and  authStatus = true
    // if not ok - show error and  authStatus = true
}

export const setNewClientAddedStatus = (status: boolean) => (dispatch: any) => {
    // debugger
    dispatch(setNewClientAddedStatusAC(status))
}
*/


//

export default clearingCompaniesReducer;