import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
// import {setLoading} from "../../redux/affReducer";
import Affiliates from './Affiliates';
import { getClients } from '../../redux/clientsReducer';
import {getAffs, removeAff} from "../../redux/affReducer";

const AffiliatesContainer : React.FC = (props) => {
    return (
        <Affiliates {...props}/>
    );
};

let mapStateToProps:any = (state:any) => {

    // debugger;
    return {
        // auth: state.auth,
        // clients: state.clients,
        // brands: state.brands,
        // desks: state.desks,
        // campaigns: state.campaigns,
        // users: state.users,
        // statuses: state.statuses,
        affs: state.affs,
    }
};

export default compose(connect(mapStateToProps,{getAffs,removeAff}))(AffiliatesContainer);
