import React, {useEffect} from 'react';
import {Col, ConfigProvider, Row, theme} from "antd";
import SideMenuContainer from "../SideMenu/SideMenuContainer";
import {Route, Routes} from "react-router-dom";
import Home from "../Home/Home";
import LoginContainer from "../LoginContainer/LoginContainer";
import SignUp from "../SignUp/SignUp";
import Clients from "../Clients/Clients";
import UsersContainer from "../Users/UsersContainer";
import AddUserContainer from "../AddUser/AddUserContainer";
import css from './Theme.module.css'
import Main from "../Main/Main";
import {useTranslation} from "react-i18next";
import {getCookie, setCookie} from "../../common/cookies";
import {setTheme} from "../../redux/appReducer";
import MainContainer from '../Main/MainContainer';

const { useToken } = theme;
const Theme = (props:any) => {
    useEffect(()=>{
        if(getCookie('theme') === 'night'){
            switchNightTheme();
        }else{
            switchDayTheme();
        }
            },[])
    const { t, i18n} = useTranslation('',{ keyPrefix: 'theme' });

    const { token } = useToken();
    let darkTheme = {
        colorPrimaryBg: '#0f0a15',
        colorBgLayout: '#0f0a15',
        colorPrimary: '#3e2069',
        colorTextBase: '#eae0f3',
        colorLink: '#854eca',
        colorBgElevated: '#24163a',

    }
    let lightTheme = {
        colorPrimaryBg: '#ffffff',
        colorBgLayout: '#ffffff',
        colorPrimary: '#3e2069',
        colorTextBase: '#1a1325',
        colorLink: '#854eca',
        colorBgElevated: '#eae0f3',
    }

    let currentTheme = {
        colorPrimaryBg: lightTheme.colorPrimaryBg,
        colorPrimary: lightTheme.colorPrimary,
        colorTextBase: lightTheme.colorTextBase,
        colorLink: lightTheme.colorLink,
        colorBgLayout: lightTheme.colorPrimaryBg,
        colorBgElevated: lightTheme.colorBgElevated,
    }

    const [primary, setPrimary] = React.useState(currentTheme.colorPrimary);
    const [primaryBg, setPrimaryBg] = React.useState(currentTheme.colorPrimaryBg);
    const [colorBgLayout, setColorBgLayout] = React.useState(currentTheme.colorBgLayout);
    const [colorTextBase, setColorTextBase] = React.useState(currentTheme.colorTextBase);
    const [colorLink, setColorLink] = React.useState(currentTheme.colorLink);
    const [colorBgElevated, setColorBgElevated] = React.useState(currentTheme.colorBgElevated);
    const [algorithm, setAlgorithm] = React.useState('light');

    const switchDayTheme = () => {
        setCookie('theme','day',365);
        // const { token } = useToken();
        // console.log(token)
        // console.log(lightTheme)
        // currentTheme.colorPrimaryBg = lightTheme.colorPrimaryBg;
        // currentTheme.colorPrimary = lightTheme.colorPrimary;
        // currentTheme.colorTextBase = lightTheme.colorTextBase;
        // currentTheme.colorLink = lightTheme.colorLink;
        setPrimary(lightTheme.colorPrimary)
        setPrimaryBg(lightTheme.colorPrimaryBg)
        setColorTextBase(lightTheme.colorTextBase)
        setColorLink(lightTheme.colorLink)
        setColorBgLayout(lightTheme.colorBgLayout)
        setColorBgElevated(lightTheme.colorBgElevated)
        setAlgorithm('light')
        document.body.style.backgroundColor = lightTheme.colorPrimaryBg;
        document.body.style.color = lightTheme.colorTextBase;
        props.setTheme('light')
    }

    const switchNightTheme = () => {
        setCookie('theme','night',365);
        // const { token } = useToken();
        // console.log(theme)
        // console.log(darkTheme)
        // currentTheme.colorPrimaryBg = darkTheme.colorPrimaryBg;
        // currentTheme.colorPrimary = darkTheme.colorPrimary;
        // currentTheme.colorTextBase = darkTheme.colorTextBase;
        // currentTheme.colorLink = darkTheme.colorLink;
        setPrimary(darkTheme.colorPrimary)
        setPrimaryBg(darkTheme.colorPrimaryBg)
        setColorTextBase(darkTheme.colorTextBase)
        setColorLink(darkTheme.colorLink)
        setColorBgLayout(darkTheme.colorBgLayout)
        setColorBgElevated(darkTheme.colorBgElevated)
        setAlgorithm('dark')
        document.body.style.backgroundColor = darkTheme.colorPrimaryBg;
        document.body.style.color = darkTheme.colorTextBase;
        props.setTheme('dark')
    }


    return (
        <ConfigProvider
            theme={{
                // 1. Use dark algorithm
                algorithm: algorithm === 'light' ? theme.defaultAlgorithm : theme.darkAlgorithm,
                token:{
                    colorPrimaryBg: primaryBg,//currentTheme.colorPrimaryBg,
                    colorPrimary: primary,//currentTheme.colorPrimary,
                    colorLink: colorLink,
                    colorTextBase: colorTextBase,
                    colorText: colorTextBase,
                    // colorTextTertiary: colorTextBase,
                    // colorTextSecondary: colorTextBase,
                    colorPrimaryText: colorTextBase,
                    // colorInfoText: colorTextBase,
                    colorBgLayout: colorBgLayout,
                    colorBgContainer: colorBgLayout,

                    // colorBgContainer: '#320047',

                },
                components:{
                    TreeSelect: {
                        colorPrimaryBg: primaryBg,
                        colorBgElevated: colorBgElevated,
                    },
                    Select:{
                        colorPrimaryBg: primaryBg,
                        colorBgElevated	: colorBgElevated,
                        // colorTextBase: '#f00',
                        // colorText: '#f00',
                        // colorTextTertiary: '#f00',
                        // colorTextSecondary: '#f00',
                        // colorPrimaryText: '#f00',
                        // colorInfoText: '#f00',
                        // colorBgLayout: '#009300',
                        // colorBgContainer: '#009300',
                        // colorBgBase: '#009300',
                        // colorBgBlur: '#009300',
                        // // colorBgLayout: '#009300',
                        // colorBgMask: '#009300',
                        // colorBgSpotlight: '#009300',
                        // colorBgContainerDisabled: '#009300',
                        // colorBgTextActive: '#009300',
                        // colorBgTextHover: '#009300',
                        // controlItemBgActive: '#009300',
                        // controlItemBgActiveHover: '#009300',
                        // controlItemBgActiveDisabled: '#009300',
                        // controlItemBgHover: '#009300',
                        //
                        //
                        // colorBgBlur	: '#009300',
                        // colorBgContainer	: '#009300',
                        // // colorBgElevated	: '#009300',
                        // colorBgLayout	: '#009300',
                        // colorBgMask	: '#009300',
                        // colorBgSpotlight	: '#009300',
                        // colorBorder	: '#009300',
                        // colorBorderSecondary	: '#009300',
                        // colorErrorActive	: '#009300',
                        // colorErrorBg	: '#009300',
                        // colorErrorBgActive	: '#009300',
                        // colorErrorBgHover	: '#009300',
                        // colorErrorBorder	: '#009300',
                        // colorErrorBorderHover	: '#009300',
                        // colorErrorHover	: '#009300',
                        // colorErrorText	: '#009300',
                        // colorErrorTextActive	: '#009300',
                        // colorErrorTextHover	: '#009300',
                        // colorFill	: '#009300',
                        // colorFillQuaternary	: '#009300',
                        // colorFillSecondary	: '#009300',
                        // colorFillTertiary	: '#009300',
                        // colorInfoActive	: '#009300',
                        // colorInfoBg	: '#009300',
                        // colorInfoBgHover	: '#009300',
                        // colorInfoBorder	: '#009300',
                        // colorInfoBorderHover	: '#009300',
                        // colorInfoHover	: '#009300',
                        // colorInfoText	: '#009300',
                        // colorInfoTextActive	: '#009300',
                        // colorInfoTextHover	: '#009300',
                        // colorLinkActive	: '#009300',
                        // colorLinkHover	: '#009300',
                        // colorPrimaryActive	: '#009300',
                        // colorPrimaryBgHover	: '#009300',
                        // colorPrimaryBorder	: '#009300',
                        // colorPrimaryBorderHover	: '#009300',
                        // colorPrimaryHover	: '#009300',
                        // colorPrimaryText	: '#009300',
                        // colorPrimaryTextActive	: '#009300',
                        // colorPrimaryTextHover	: '#009300',
                        // colorSuccessActive	: '#009300',
                        // colorSuccessBg	: '#009300',
                        // colorSuccessBgHover	: '#009300',
                        // colorSuccessBorder	: '#009300',
                        // colorSuccessBorderHover	: '#009300',
                        // colorSuccessHover	: '#009300',
                        // colorSuccessText	: '#009300',
                        // colorSuccessTextActive	: '#009300',
                        // colorSuccessTextHover	: '#009300',
                        // colorText	: '#009300',
                        // colorTextQuaternary	: '#009300',
                        // colorTextSecondary	: '#009300',
                        // colorTextTertiary	: '#009300',
                        // colorWarningActive	: '#009300',
                        // colorWarningBg	: '#009300',
                        // colorWarningBgHover	: '#009300',
                        // colorWarningBorder	: '#009300',
                        // colorWarningBorderHover	: '#009300',
                        // colorWarningHover	: '#009300',
                        // colorWarningText	: '#009300',
                        // colorWarningTextActive	: '#009300',
                        // colorWarningTextHover	: '#009300',
                        // colorWhite	: '#009300',
                        // motionDurationFast	: '#009300',
                        // motionDurationMid	: '#009300',
                        // motionDurationSlow	: '#009300',

                    },
                    Card:{

                        // colorPrimaryBg: "#ccc",
                        // colorBgElevated: "#ccc",
                        // colorBgLayout: "#ccc",
                        colorBgContainer: colorBgElevated,
                    },
                    Layout:{
                        headerBg: colorBgLayout,//currentTheme.colorPrimaryBg,
                        siderBg: colorBgLayout,//currentTheme.colorPrimaryBg,
                        // colorPrimary: primary,//currentTheme.colorPrimary,
                        // colorLink: colorLink,
                        // colorTextBase: colorTextBase,
                        // colorText: colorTextBase,
                        // // colorTextTertiary: colorTextBase,
                        // // colorTextSecondary: colorTextBase,
                        // colorPrimaryText: colorTextBase,
                        // // colorInfoText: colorTextBase,
                        // colorBgLayout: colorBgLayout,
                        // colorBgContainer: colorBgLayout,
                    },
                    Popover:{
                        // lineHeight:1,
                        fontSize: 12,
                        colorPrimaryBg: primaryBg,
                        colorBgElevated	: colorBgElevated,
                    },
                }

                // 2. Combine dark algorithm and compact algorithm
                // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
                // components:{
                //     Row:{
                //         colorPrimary: '#422'
                //     }
                // }
            }}
        >

            <div className={css.themeSwitcher}>
                <span className={`${css.day}  `} onClick={switchDayTheme}>{t('Day')}</span> | <span
                className={`${css.night}  `} onClick={switchNightTheme}>{t('Night')}</span>
            </div>

            <MainContainer />

        </ConfigProvider>
    );
};

export default Theme;
