import axios from "axios";
import {getClientDetails} from "../redux/clientsReducer";


let APIkey = 'letmeintroducemyself59585747348';
// let baseURL = 'https://testmycode.online/btc/ajax.php';
let baseURL = 'https://crm39if88488f7.testmycode.online/main/api/';

let instance = axios.create({
    withCredentials: true,
    baseURL: baseURL,
    headers: {
        'API-KEY': APIkey
    }
});

export const API = {
    getTestData() {
        return instance.get('test')
            .then(responce => {
                return responce.data;
            })
            .catch(error => {
                return error.response
            })
    },



    addUser(email: string | undefined,password: string | undefined,fullName: string | undefined,isActive: boolean | undefined,desk: string | undefined,role: string | undefined,brand: string | undefined,supervisor: number | undefined) {
        // debugger;
        return instance.post('addUser/',{

            email: email,
            password: password,
            fullName: fullName,
            isActive: isActive,
            desk: desk,
            role: role,
            brand: brand,
            supervisor: supervisor,

        })
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    addClient(email: string | undefined,password: string | undefined,fullName: string | undefined,
              isActive: boolean | undefined,isOnline: boolean | undefined,lastLogInDate: number | undefined,
              lastActionDate: number | undefined,lastAction: string | undefined,deskID: string | undefined,
              typeID: string | undefined,brandID: string | undefined,createDate: number | undefined,
              createdBy: string | undefined,assignedToID: number | undefined,phone: string | undefined,
              address: string | undefined,statusID: string | undefined,lastCommentDate: number | undefined,
              countryID: string | undefined,campaignID: number | undefined) {
        // debugger;
        return instance.post('addClient/',{

            email:email,
            password:password,
            fullName:fullName,
            isActive:isActive,
            isOnline:isOnline,
            lastLogInDate:lastLogInDate,
            lastActionDate:lastActionDate,
            lastAction:lastAction,
            deskID:deskID,
            typeID:typeID,
            brandID:brandID,
            createDate:createDate,
            createdBy:createdBy,
            assignedToID:assignedToID,
            phone:phone,
            address:address,
            statusID:statusID,
            lastCommentDate:lastCommentDate,
            countryID:countryID,
            campaignID:campaignID

        })
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    addAff(title: string | undefined,details: string | undefined,isActive: boolean | undefined) {
        // debugger;
        return instance.post('addAff/',{
            title:title,
            details:details,
            isActive:isActive

        })
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    addCampaign(title: string | undefined,affID: number | undefined,isActive: boolean | undefined) {
        // debugger;
        return instance.post('addCampaign/',{
            title:title,
            affID:affID,
            isActive:isActive

        })
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },


    getAll(){
        return instance.get('getAll/')
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    getUsers(limit:number = 10, offset:number = 0, order:string = 'ASC', orderBy: string = 'userID',field: string = '', search: string = ''){
        return instance.get('getUsers/',{
            params:{
                limit:limit,
                offset:offset,
                order:order,
                orderBy:orderBy,
                field:field,
                search:search,

            }
        })
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    getClients(arrParams: any[] = [], limit:number = 10, offset:number = 0, orderBy: string = 'clientID',order:string = 'ASC', field: string = '', search: string = ''){
        return instance.post('getClients/',{

                arrParams:arrParams,
                limit:limit,
                offset:offset,
                order:order,
                orderBy:orderBy,
                field:field,
                search:search,


        })
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    getDesks(){
        return instance.get('getDesks/')
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    getBrands(){
        return instance.get('getBrands/')
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    getCountries(){
        return instance.get('getCountries/')
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    getStatuses(){
        return instance.get('getStatuses/')
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    getClearingCompanies(){
        return instance.get('getClearingCompanies/')
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    getAffs(){
        return instance.get('getAffs/')
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    getCampaigns(){
        return instance.get('getCampaigns/')
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    getClientDetails(id:number){
        return instance.get('getClientDetails/',{
            params:{
                id:id,
            }
        })
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                console.log('getClientDetails error')
                return error.response
            })
    },

    authStatus(){
        return instance.get('authStatus/')
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    changeStatus(clientID: number, statusID: number){
        return instance.post('changeStatus/',{

                clientID:clientID,
                statusID:statusID

        })
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    exit(id: number | undefined){
        return instance.post('exit/',{
            id: id
        })
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    login(email:string,pass:string,rememberMe:boolean) {
        return instance.post('login/',{
            email: email,
            pass: pass,
            rememberMe: rememberMe,
        })
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    updateClient(clientUpdateDetails:any) {
        return instance.post('updateClient/',{
            clientUpdateDetails: clientUpdateDetails,

        })
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    removeAff(affID:number) {
        return instance.delete('removeAff/',{
            params: {
                affID: affID,
            }
        })
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },
    removeCamp(campID:number) {
        return instance.delete('removeCamp/',{
            params: {
                campID: campID,
            }
        })
            .then(response => {
                return response.data.data;
            })
            .catch(error => {
                return error.response
            })
    },


}