// import React from 'react';
// import {Skeleton} from "antd";
//
// const Users = () => {
//     return (
//         <div>
//             {/*<h1>Users Page</h1>*/}
//             {/*<p>Users will be here soon...</p>*/}
//             <h1>Users Page</h1>
//             <p>(список пользователей CRM будет доступен только тимам и админам)</p>
//             <Skeleton />
//         </div>
//     );
// };

import React, { useEffect, useState } from 'react';
import type { GetProp, TableProps } from 'antd';
import {Input, Table} from 'antd';
import qs from 'qs';
import {SorterResult} from "antd/lib/table/interface";
import {getUsers, setLoading} from "../../redux/usersReducer";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import css from "./Users.module.css";
import { CheckOutlined, MinusOutlined } from '@ant-design/icons';

type ColumnsType<T extends object = object> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
    lastLogInDate: number;
    lastAction: any;
    lastActionDate: number;
    fullName: string;
    // lastName: string;

    // gender: string;
    email: string;
    // dob: {
    //     age: number;
    //     date: string;
    //
    // }
    userID: number,
    createDate: number,
    desk: string
    minWidth: string
    isActive: string
    isOnline: string
    brand: string
    createdBy: string
    supervisor: string
    // login: {
    //     uuid: string;
    // };
}

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: SorterResult<any>['field'];
    sortOrder?: SorterResult<any>['order'];
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}


// const getRandomuserParams = (params: TableParams) => ({
//     results: params.pagination?.pageSize,
//     page: params.pagination?.current,
//     ...params,
// });

const Users: React.FC = (props: any) => {

    const { t } = useTranslation('',{ keyPrefix: 'users' });

    // const [data, setData] = useState<DataType[]>();
    // const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [dataSource, setDataSource] = useState(props.users.users);
    const [value, setValue] = useState('');

    useEffect(()=>{
        props.setLoading(true);
        props.getUsers(100);
    },[])

    useEffect(()=>{
        setDataSource(props.users.users);
    },[props.users.users])

    const FilterByNameInput = (
        <Input
            placeholder="Search Name"
            value={value}
            onChange={e => {
                const currValue = e.target.value;
                setValue(currValue);
                const filteredData = props.users.users.filter((entry:any) =>
                    // fullName = entry.firstName + ' ' + entry.lastName;
                    entry.fullName.toLowerCase().includes(currValue.toLowerCase())
                );
                setDataSource(filteredData);
                // todo - change to filtering on server
            }}
        />
    );

    let deskFilters = props.desks.desks.map((d:any) => ({
        text: d.name,
        value: d.deskID
    }))

    // @ts-ignore
    // @ts-ignore
    const columns: ColumnsType<DataType> = [
        {
            title: 'UserID',
            dataIndex: 'userID',
            // sorter: (a,b) => (a.desk).localeCompare(b.desk),
            // width: '20%',
            // width: 100,
            onHeaderCell: () => ({
                style: { minWidth: '50px' }, // Задаём минимальную ширину через стили
            }),
        },
        {
            title: FilterByNameInput,
            dataIndex: 'fullName',
            // sorter: (a,b) => (a.firstName+a.lastName).localeCompare(b.firstName+b.lastName),
            // render: (text, record) => (
            //     `${record.firstName} ${record.lastName}`
            // ),
            // width: '20%',
            // width: 400,
            onHeaderCell: () => ({
                style: { minWidth: '200px' }, // Задаём минимальную ширину через стили
            }),
        },
        {
            title: 'Desk',
            dataIndex: 'desk',
            filters: deskFilters,
            sorter: (a,b) => (a.desk).localeCompare(b.desk),
            // width: 300,
            render: (_, d) => {

                const desk = props.desks.desks.find(
                    (desk: any) => desk.deskID === d.desk
                );
                return desk ? <div key={desk.deskID}>{desk.name}</div> : null;
            },
            onHeaderCell: () => ({
                style: { minWidth: '200px' }, // Задаём минимальную ширину через стили
            }),
        },
        {
            title: 'Create date',
            dataIndex: 'createDate',
            filters: [
            ],
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.createDate - b.createDate,
            // sorter: true,
            // width: '20%',
            render: (_,d) => new Date(d.createDate*1000).toUTCString(),
            onHeaderCell: () => ({
                style: { minWidth: '200px' }, // Задаём минимальную ширину через стили
            }),
        },
        // {
        //     title: 'Age',
        //     dataIndex: 'dob',
        //     filters: [
        //     ],
        //     sorter: true,
        //     width: '20%',
        //     render: (a) => a.age,
        // },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a,b) => (a.email).localeCompare(b.email),
            // width: '20%',
            onHeaderCell: () => ({
                style: { minWidth: '200px' }, // Задаём минимальную ширину через стили
            }),
        },
        {
            title: 'is Active',
            dataIndex: 'isActive',
            // width: '20%',
            onHeaderCell: () => ({
                style: { minWidth: '100px' }, // Задаём минимальную ширину через стили
            }),
            render: (_,d) => d.isActive === '1' ? <CheckOutlined /> : <MinusOutlined />,
        },
        {
            title: 'is Online',
            dataIndex: 'isOnline',
            // width: '20%',
            onHeaderCell: () => ({
                style: { minWidth: '100px' }, // Задаём минимальную ширину через стили
            }),
            render: (_,d) => d.isOnline === '1' ? <CheckOutlined /> : <MinusOutlined />,
        },
        {
            title: 'Last Action Date',
            dataIndex: 'lastActionDate',
            filters: [
            ],
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.lastActionDate - b.lastActionDate,
            // sorter: true,
            // width: 300,
            render: (_,d) => <span title={d.lastAction}>{ new Date(d.lastActionDate*1000).toUTCString() }</span>,
            onHeaderCell: () => ({
                style: { minWidth: '200px' }, // Задаём минимальную ширину через стили
            }),
        },
        {
            title: 'Last LogIn Date',
            dataIndex: 'lastLogInDate',
            // width: 350,
            render: (_,d) =>  d.lastLogInDate > 0 ? new Date(d.lastLogInDate*1000).toUTCString() : 'no login yet' ,
            onHeaderCell: () => ({
                style: { minWidth: '200px' }, // Задаём минимальную ширину через стили
            }),
        },
        {
            title: 'brand',
            dataIndex: 'brand',
            // width: '20%',
            onHeaderCell: () => ({
                style: { minWidth: '200px' }, // Задаём минимальную ширину через стили
            }),
            render: (_, d) => {

                const brand = props.brands.brands.find(
                    (brand: any) => brand.brandID === d.brand
                );
                return brand ? <div key={brand.brandID}>{brand.name}</div> : null;
            },
        },
        {
            title: 'created By',
            dataIndex: 'createdBy',
            onHeaderCell: () => ({
                style: { minWidth: '200px' }, // Задаём минимальную ширину через стили
            }),
            render: (_, d) => {

                const user = props.users.users.find(
                    (user: any) => user.userID === d.createdBy
                );
                return user ? <div key={user.userID}>{user.fullName}</div> : d.createdBy;
            },
        },
        {
            title: 'supervisor',
            dataIndex: 'supervisor',
            onHeaderCell: () => ({
                style: { minWidth: '200px' }, // Задаём минимальную ширину через стили
            }),
            render: (_, d) => {

                const user = props.users.users.find(
                    (user: any) => user.userID === d.supervisor
                );
                return user ? <div key={user.userID}>{user.fullName}</div> : (d.supervisor === '0' ? '-' : d.supervisor) ;
            },
        },
    ];

    /*

        userID(pin):"1"
        email(pin):"super@admin.com"
        password(pin):""
        fullName(pin):"Andrew Admin"
        isActive(pin):"1"
        isOnline(pin):"0"
        lastLogInDate(pin):"0"
        lastActionDate(pin):"1734885811"
        lastAction(pin):"registered"
        desk(pin):"1"
        role(pin):"0"
        brand(pin):"1"
        createDate(pin):"1734885811"
        createdBy(pin):"7"
        supervisor(pin):"0"

    */


    const fetchData = () => {
        // setLoading(true);
        // fetch(`https://randomuser.me/api?${qs.stringify(getRandomuserParams(tableParams))}`)
        //     .then((res) => res.json())
        //     .then(({ results }) => {
        //         // setData(results);
        //         setLoading(false);
        //         setTableParams({
        //             ...tableParams,
        //             pagination: {
        //                 ...tableParams.pagination,
        //                 total: 200,
        //                 // 200 is mock data, you should read it from server
        //                 // total: data.totalCount,
        //             },
        //         });
        //     });
    };

    useEffect(() => {
        fetchData();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        JSON.stringify(tableParams.filters),
    ]);

    const handleTableChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
            sortField: Array.isArray(sorter) ? undefined : sorter.field,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // setData([]);
        }
    };

    return (
        <>
            <h1>{t("Users List")}</h1>

                <Link to="/adduser" className={css.add} >{t('Add User')}</Link>
            <Table

                className={css.table}
                columns={columns}
                rowKey={(record) => record.userID}
                dataSource={dataSource}
                pagination={tableParams.pagination}
                loading={props.users.loading}
                onChange={handleTableChange}
                bordered
                size="small"
                rowSelection={{
                }}
                scroll={{ x: 'max-content'}}
                tableLayout="auto"
            />
        </>

    );
};

export default Users;
