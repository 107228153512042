
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Checkbox, GetProp, Layout, Popconfirm, PopconfirmProps, Popover, TableProps, message, Button} from 'antd';
import {Input, Select, Table} from 'antd';
import qs from 'qs';
import {SorterResult} from "antd/lib/table/interface";
import {getUsers, setLoading} from "../../redux/usersReducer";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {
    CaretDownOutlined,
    CaretUpOutlined, FilterFilled,
    SearchOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined
} from "@ant-design/icons";
import css from "../Affiliates/Affiliates.module.css";
import { createStyles } from 'antd-style';
import {changeStatus, getClients} from "../../redux/clientsReducer";
import {debug} from "util";
import {Content} from "antd/lib/layout/layout";

import type { CheckboxProps } from 'antd';
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import {getAffs, removeAff} from "../../redux/affReducer";

type ColumnsType<T extends object = object> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface DataType {
    affID: number;
    // statusID: number;
    fullName: string;
    // lastName: string;

    // gender: string;
    email: string;
    // dob: {
    //     age: number;
    //     date: string;
    //
    // }
    clientID: number,
    createDate: number,
    desk: string,
    phone: string,
    password: string,
    isActive: string,
    isOnline: string,
    lastLogInDate: number,
    lastActionDate: number,
    lastAction: string,
    type: string,
    brand: string,
    assignedTo: string,
    status: string,
    country: string,
    campaign: string,
    utm_campaign: string,
    ip: string,
    comment: string,
    additional_info: string,
    lastCommentDate: number,
    // login: {
    //     uuid: string;
    // };
}

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: SorterResult<any>['field'];
    sortOrder?: SorterResult<any>['order'];
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}


// const getRandomuserParams = (params: TableParams) => ({
//     results: params.pagination?.pageSize,
//     page: params.pagination?.current,
//     ...params,
// });

const Affiliates: React.FC = (props: any) => {

    const { t } = useTranslation('',{ keyPrefix: 'affs' });

    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    // one time load data from server
    useEffect(()=>{

        if (props.affs.count === 0) {
            props.setLoading(true);
            // console.log(1)
            props.getAffs();
        }

    },[])

    // if store updates
    useEffect(()=>{
        setDataSource(props.affs.affs);
    },[props.affs.affs])


    // main dataSource
    const [dataSource, setDataSource] = useState(props.affs.affs);
    // const [filteredStatuses, setFilteredStatuses] = useState(props.statuses.statuses);
    //////////////////////////////////////////////   for filtering ----- start  //////////////////////////////////////////////



    //////////////////////////////////////////////   for filtering ----- end  //////////////////////////////////////////////

    const title = '';


    // const content = (
    //     <div>
    //         <span>Content 1</span> <span>Content 2</span>
    //     </div>
    // );

    let statusChanged = (value: any,clientID: number) => {
        props.changeStatus(clientID,value.value)
    }

    // affID(pin):"1"
    // title(pin):"test aff 1"
    // details(pin):"contact@aff1.com"
    // isActive(pin):"1"

    let remove = (affID:number) => {
        // todo add confirmation yes/no window
        props.removeAff(affID);
    }


    // const confirm: PopconfirmProps['onConfirm'] = (e,affID:number) => {
    //     console.log(e);
    //     message.success('Click on Yes');
    //     remove(affID)
    // };

    const cancel: PopconfirmProps['onCancel'] = (e) => {
        console.log(e);
        message.error('Click on No');
    };

    const columns: ColumnsType<DataType> = [
        {
            title:

                    <div>{t('ID')}</div>

                ,
            dataIndex: 'affID',
            // sorter: (a,b) => (a.firstName+a.lastName).localeCompare(b.firstName+b.lastName),
            // render: (text, record) => (
            //
            //     <Link to={`/client/${record.clientID}`} className={css.link}>{record.fullName}</Link>
            // ),
            // width: '20%',
            width: 'auto',
            fixed: 'left',
            key: 'affID'
        },
        {
            title:
                    <div>{t('Title')}</div>,
            dataIndex: 'title',
           // filters: [
            //     { text: 'Poland', value: 'poland' },
            //     { text: 'Spain', value: 'spain' },
            // ],
            // sorter: {
            //     compare: (a,b) => (a.phone).localeCompare(b.phone),
            //     multiple: 2,
            // },
            width: 'auto',
            // fixed: 'left',
            key: 'title'
        },
        {
            title:
                    <div>{t('Details')}</div>
            ,

            dataIndex: 'details',
            // sorter: (a,b) => (a.email).localeCompare(b.email),
            width: 'auto',
            // fixed: 'left',
            key: 'details'
        },
        //
        // {
        //     title: t('Desk'),
        //     dataIndex: 'desk',
        //     // filters: [
        //     //     { text: 'Poland', value: 'poland' },
        //     //     { text: 'Spain', value: 'spain' },
        //     // ],
        //     // sorter: {
        //     //     compare: (a,b) => (a.desk).localeCompare(b.desk),
        //     //     multiple: 2,
        //     // },
        //     showSorterTooltip: {
        //         target: 'sorter-icon'
        //     },
        //     width: 'auto',
        //
        //     // render: (_, d) => {
        //     //
        //     //     const desk = props.desks.desks.find(
        //     //         (desk: any) => desk.deskID === d.desk
        //     //     );
        //     //     return desk ? <div key={desk.deskID}>{desk.name}</div> : null;
        //     // },
        //     key: 'desk'
        // },
        // { title:
        //             <div>{t('Status')}</div>
        //     ,
        //     dataIndex: 'status', key: 'status', width: '150px',
        //     // sorter: (a,b) => (a.status).localeCompare(b.status),
        //     // render: (_, d) => {
        //     //         // console.log(d)
        //     //     const status = props.statuses.statuses.find(
        //     //         (status: any) => status.statusID === d.status
        //     //     );
        //     //
        //     //         let statuses_arr: any = [];
        //     //
        //     //     {props.statuses.statuses.map((st:any,k:number)=>{
        //     //         return st.isActive === '1' ?
        //     //             statuses_arr.push({
        //     //                 value: st.statusID,
        //     //                 label: <div key={st.statusID} style={{  backgroundColor: st.bgСolor !== '' ? st.bgСolor : 'transparent' , color: st.color, textAlign: "center", borderRadius: 5}}>{st.name}</div> ,
        //     //             })
        //     //              : null;
        //     //     })}
        //     //     // console.log(statuses_arr)
        //     //     return status ?
        //     //
        //     //
        //     //         <Select labelInValue style={{minWidth: 200}} defaultValue={status.statusID}
        //     //                 options={statuses_arr} variant="borderless"
        //     //                 onChange={(v)=>statusChanged(v,d.clientID)}
        //     //         >
        //     //
        //     //         </Select>
        //     //
        //     //
        //     //         // <div key={status.statusID} style={{ backgroundColor: status.bgСolor, color: status.color, textAlign: "center"}}>{status.name}</div>
        //     //
        //     // : null;
        //     //
        //     //
        //     // },
        // },
        // {
        //     title: t('Create date'),
        //     dataIndex: 'createDate',
        //     // filters: [
        //     // ],
        //     // defaultSortOrder: 'descend',
        //     // sorter: {
        //     //     compare: (a, b) => a.createDate - b.createDate,
        //     //     multiple: 2,
        //     // },
        //     // sorter: true,
        //     width: 'auto',
        //     // render: (_,d) => Intl.DateTimeFormat('ru',{timeStyle:'medium',dateStyle:'short'}).format(d.createDate*1000),
        //     key: 'createDate'
        // },

        { title: t('Active'), dataIndex: 'isActive', key: 'isActive',
            width: '150px',
            render: (_,d) => d.isActive === '1' ? 'yes' : 'no',
        },


        {
            title: t('Action'),
            key: 'operation',
            fixed: 'right',
            width: 100,
            // render: () => <a>action</a>,
            render: (_,d) => <div className={css.link}
                                  // onClick={()=>remove(d.affID)}
            >
                <Popconfirm
                    title="Delete aff"
                    description="Are you sure to delete this aff?"
                    onConfirm={()=>remove(d.affID)}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button danger>{t('remove')}</Button>
                </Popconfirm>
            </div>,
        },
    ];
    const fetchData = () => {
    };

    useEffect(() => {
        fetchData();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        JSON.stringify(tableParams.filters),
    ]);

    const handleTableChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
            sortField: Array.isArray(sorter) ? undefined : sorter.field,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // setData([]);
        }
    };

    // const { styles } = useStyle();
    return (
        <>
            <h1>{t("Affiliates List")}</h1>
            <Link to="/addAff" className={css.add}>{t('addAff')}</Link>
            <Table
                className={css.table}

                columns={columns}
                rowKey={(record) => record.affID}
                dataSource={dataSource}
                pagination={tableParams.pagination}
                loading={props.affs.loading}
                onChange={handleTableChange}
                bordered
                size="small"
                rowSelection={{
                }}

                scroll={{ x: 'max-content', y: 105 * 5 }}
            />
        </>

    );
};

export default Affiliates;
