import {API} from "../api/API";
import store from "./store";
import {setClientsAC,setLoading as setClientsLoading} from "./clientsReducer";
import {setDesksAC} from "./desksReducer";
import {setCountriesAC} from "./countriesReducer";
import {setStatusesAC} from "./statusesReducer";
import {setCampaignsAC} from "./campaignsReducer";
import {setBrandsAC} from "./brandsReducer";
import {setUsersAC} from "./usersReducer";
import {getAuthStatus, setAuthStatusAC, setUserIdAC} from "./authReducer";

// let state = store.getState();

const SET_AFFS = 'crm/aff/SET_AFFS';
const SET_LOADING = 'crm/aff/SET_LOADING';
const SET_NEW_AFF_ADDED = 'crm/aff/SET_NEW_AFF_ADDED';
const SET_AFF_REMOVED = 'crm/aff/SET_AFF_REMOVED';
// const SET_AUTH_DATA = 'crm/auth/SET_AUTH_DATA';

type Aff = {
    affID: number | null,
    title: string | null,
    details: string | null,
    isActive: boolean | null

}

let initialState = {
    affs:[] as Array<Aff>,
    loading: false as boolean | null,
    newAffAdded: false as boolean | null,
    affRemoved: false as boolean | null,

}

const affReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_AFFS:
            return {
                ...state,
                affs: action.affs
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SET_NEW_AFF_ADDED:
            return {
                ...state,
                newAffAdded: action.newAffAdded
            };
        case SET_AFF_REMOVED:
            return {
                ...state,
                affRemoved: action.affRemoved
            };
        // case SET_THEME:
        //     return {
        //         ...state,
        //         theme: action.theme
        //     };

        default:
            return state;
    }
}

// let onAuthStatusSet = (store.dispatch,state) => {
//
// }

// ActionCreator

const setAffRemovedAC = (affRemoved: boolean) => (
    {
        type: SET_AFF_REMOVED,
        affRemoved: affRemoved
    }
)
const setNewAffAddedAC = (newAffAdded: boolean) => (
    {
        type: SET_NEW_AFF_ADDED,
        newAffAdded: newAffAdded
    }
)
const setLoadingAC = (loading: boolean) => (
    {
        type: SET_LOADING,
        loading: loading
    }
)
export const setAffsAC = (affs: any) => (
    {
        type: SET_AFFS,
        affs: affs
    }
)
// const setThemeAC = (theme: string) => (
//     {
//         type: SET_THEME,
//         theme: theme
//     }
// )
// const setAllAC = (all: object) => (
//     {
//         type: SET_THEME,
//         theme: theme
//     }
// )

// const setAuthDataAC = (login: string, pass: string, rememberMe: boolean) => (
//     {
//         type: SET_AUTH_DATA,
//         login: login,
//         pass: pass,
//         rememberMe: rememberMe,
//     }
// )

// ThunkCreator

export const setLoading = (loading: boolean) => (dispatch: any) => {
    dispatch(setLoadingAC(loading))
}
export const setNewAffAdded = (newAffAdded: boolean) => (dispatch: any) => {
    dispatch(setNewAffAddedAC(newAffAdded))
}

export const getAffs = () => async (dispatch: any) => {
    dispatch(setLoadingAC(true))
    await API.getAffs()
        .then((data)=>{
            console.log(data)
            dispatch(setAffsAC(JSON.parse(data.affs)))
            dispatch(setLoadingAC(false))
        })
        .catch((error)=>{
            console.error('error: ' + error)
            dispatch(getAuthStatus())
        })

}

export const removeAff = (affID: number) => async (dispatch: any) => {
    // dispatch(setLoadingAC(true))
    await API.removeAff(affID)
        .then((data)=>{
            console.log(data)
            dispatch(setAffRemovedAC(true))
            dispatch(getAffs())
        }).catch((error)=>{
            console.error('error: ' + error)
            dispatch(getAuthStatus())
        })

}


export const AddAffiliate = (title: string | undefined,details: string | undefined,isActive: boolean | undefined) => async (dispatch: any) => {
    dispatch(setLoadingAC(true))
    await API.addAff(title,details,isActive)
        .then((data)=>{
            console.log(data)
            // dispatch(setAffsAC(data.affs))
            dispatch(setNewAffAddedAC(true))
            dispatch(getAffs())
        })
        .catch((error) => {
            console.error(error);
            dispatch(setLoadingAC(false)); // Сбрасываем состояние загрузки в случае ошибки
            dispatch(getAuthStatus())
        })
}


// export const setTheme = (theme: string) => (dispatch: any) => {
//     dispatch(setThemeAC(theme))
// }
// export const setActiveTab = (activeTab: string) => (dispatch: any) => {
//     dispatch(setActiveTabAC(activeTab))
// }
//
// export const getAll = ()=> async (dispatch: any) => {
//     dispatch(setClientsLoading(true))
//     await API.getAll()
//         .then((data)=>{
//             if(data.status === 'not logged in'){
//
//                 dispatch(setAuthStatusAC(false ));
//                 dispatch(setUserIdAC(0 ));
//             }else{
//                 console.log(data)
//                 if(data.clients.result === 'ok'){
//                     dispatch(setClientsAC(JSON.parse(data.clients.clients)));
//                     dispatch(setClientsLoading(false))
//                 }
//                 if(data.desks.result === 'ok'){
//                     dispatch(setDesksAC(JSON.parse(data.desks.desks)));
//                     // dispatch(setClientsLoading(false))
//                 }
//                 if(data.countries.result === 'ok'){
//                     dispatch(setCountriesAC(JSON.parse(data.countries.countries)));
//                     // dispatch(setClientsLoading(false))
//                 }
//                 if(data.statuses.result === 'ok'){
//                     dispatch(setStatusesAC(JSON.parse(data.statuses.statuses)));
//                     // dispatch(setClientsLoading(false))
//                 }
//                 if(data.campaigns.result === 'ok'){
//                     dispatch(setCampaignsAC(JSON.parse(data.campaigns.campaigns)));
//                     // dispatch(setClientsLoading(false))
//                 }
//                 if(data.brands.result === 'ok'){
//                     dispatch(setBrandsAC(JSON.parse(data.brands.brands)));
//                     // dispatch(setClientsLoading(false))
//                 }
//                 if(data.users.result === 'ok'){
//                     dispatch(setUsersAC(JSON.parse(data.users.users)));
//                     // dispatch(setClientsLoading(false))
//                 }
//
//             }
//
//
//         })
// }

// export const setAuthData = (login: string, pass: string, rememberMe: boolean) => async (dispatch: any) => {
//     dispatch(setAuthDataAC(login, pass, rememberMe))
//     await API.login(login, pass, rememberMe)
//         .then((data)=>{
//             console.log(data);
//             dispatch(setAuthStatusAC(data.status === 'logged in' ));
//         })
//         .catch((error)=>{
//             console.error('error: ' + error)
//         });
//
//     // API - auth
//     // if ok - set auth data null and  authStatus = true
//     // if not ok - show error and  authStatus = true
// }

export default affReducer;