import React, {useEffect, useState} from "react";
import {Link, Navigate, useNavigate  } from "react-router-dom";

import {eraseCookie, setCookie} from "../../common/cookies";
import css from './SideMenu.module.css'
import {useTranslation} from "react-i18next";
import {Button, Menu} from "antd";
import {setActiveTab} from "../../redux/appReducer";

import {
    AimOutlined, ApartmentOutlined,
    AppstoreOutlined,
    BankOutlined, CreditCardOutlined, DesktopOutlined, FlagOutlined,
    HomeOutlined, IdcardOutlined,
    MailOutlined,
    PhoneOutlined,
    SettingOutlined, ShopOutlined, TeamOutlined, UserOutlined
} from '@ant-design/icons';
import type { MenuProps } from 'antd';

const SideMenu: React.FC = (props: any) => {

    const clickLink = (url:string) => {
        props.setActiveTab(url)
    }

    const { t, i18n} = useTranslation('',{ keyPrefix: 'sideMenu' });

    // const setLanguage:any = (lang: string) => {
    //     setCookie('lang',lang,7);
    //     i18n.changeLanguage(lang);
    //     // console.log('sl - ' + lang)
    //     let ru_lang = window.document.getElementById('lang_ru');
    //     let en_lang = window.document.getElementById('lang_en');
    //
    //     if(lang === 'ru') {
    //         if(ru_lang) ru_lang.style.color ='#090';
    //         if(en_lang) en_lang.style.color ='';
    //     }
    //     if(lang === 'en') {
    //         if(en_lang) en_lang.style.color ='#090';
    //         if(ru_lang) ru_lang.style.color ='';
    //     }
    // }


    type MenuItem = Required<MenuProps>['items'][number];

    const items: MenuItem[] = [
        {
            label: 'Navigation One',
            key: 'mail',
            icon: <MailOutlined />,
        },
        {
            label: 'Navigation Two',
            key: 'app',
            icon: <AppstoreOutlined />,
            disabled: true,
        },
        {
            label: 'Navigation Three - Submenu',
            key: 'SubMenu',
            icon: <SettingOutlined />,
            children: [
                {
                    type: 'group',
                    label: 'Item 1',
                    children: [
                        { label: 'Option 1', key: 'setting:1' },
                        { label: 'Option 2', key: 'setting:2' },
                    ],
                },
                {
                    type: 'group',
                    label: 'Item 2',
                    children: [
                        { label: 'Option 3', key: 'setting:3' },
                        { label: 'Option 4', key: 'setting:4' },
                    ],
                },
            ],
        },
        {
            key: 'alipay',
            label: (
                <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
                    Navigation Four - Link
                </a>
            ),
        },
    ];

    const [current, setCurrent] = useState('mail');

    const onClick: MenuProps['onClick'] = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };

    // Функция для генерации элементов меню
    const generateMenuItems = (authStatus: boolean, activeMenuTab: string, t: (key: string) => string, clickLink: (path: string) => void) => {
        if (!authStatus) return [];

        return [
            {
                key: "/",
                icon: <HomeOutlined />,
                label: <Link to="/" onClick={() => clickLink("/")}>{t("Home")}</Link>,
                className: activeMenuTab === "/" ? "active" : "",
            },
            {
                key: "/clients",
                icon: <TeamOutlined />,
                label: <Link to="/clients" onClick={() => clickLink("/clients")}>{t("Clients")}</Link>,
                className: activeMenuTab === "/clients" ? "active" : "",
            },
            {
                key: "/users",
                icon: <UserOutlined />,
                label: <Link to="/users" onClick={() => clickLink("/users")}>{t("Users")}</Link>,
                className: activeMenuTab === "/users" ? "active" : "",
            },
            {
                key: "/usersHierarchy",
                icon: <ApartmentOutlined />,
                label: <Link to="/usersHierarchy" onClick={() => clickLink("/usersHierarchy")}>{t("Users Hierarchy")}</Link>,
                className: activeMenuTab === "/usersHierarchy" ? "active" : "",
            },
            {
                key: "/cc",
                icon: <BankOutlined />,
                label: <Link to="/cc">{t("Clearing Companies")}</Link>,
            },
            {
                key: "/c2c",
                icon: <PhoneOutlined />,
                label: <Link to="/c2c">{t("Click-2-Call")}</Link>,
            },
            {
                key: "/transactions",
                icon: <CreditCardOutlined />,
                label: <Link to="/transactions">{t("Transactions")}</Link>,
            },
            {
                key: "/kyc",
                icon: <IdcardOutlined />,
                label: <Link to="/kyc">{t("KYC")}</Link>,
            },
            {
                key: "/affiliates",
                icon: <AimOutlined />,
                label: <Link to="/affiliates">{t("Affiliates")}</Link>,
            },
            {
                key: "/campaigns",
                icon: <FlagOutlined />,
                label: <Link to="/campaigns">{t("Campaigns")}</Link>,
            },
            {
                key: "/brands",
                icon: <ShopOutlined />,
                label: <Link to="/brands">{t("Brands")}</Link>,
            },
            {
                key: "/desks",
                icon: <DesktopOutlined />,
                label: <Link to="/desks">{t("Desks")}</Link>,
            },
        ];
    };

    return <Menu onClick={onClick} selectedKeys={[current]}
                 items={generateMenuItems(props.auth.authStatus, props.app.activeMenuTab, t, clickLink)}

                 // style={{ width: 256 }}
                 // defaultSelectedKeys={['1']}
                 // defaultOpenKeys={['sub1']}
                 mode="inline"
    />

    // return <nav>
    //
    //     {!props.auth.authStatus ? null
    //         // <ul>
    //         //     <li>
    //         //         <Link to="/login">{t('Login')}</Link>
    //         //     </li>
    //         //     <li>
    //         //         <Link to="/signup">{t('SignUp')}</Link>
    //         //     </li>
    //         // </ul>
    //         :
    //         <>
    //             <ul>
    //                 <li>
    //                     <Link to="/" onClick={()=>clickLink('/')}
    //                           className={props.app.activeMenuTab === '/' ? css.active : undefined}>{t('Home')}</Link>
    //                 </li>
    //
    //                 <li>
    //                     <Link to="/clients" onClick={()=>clickLink('/clients')}
    //                           className={props.app.activeMenuTab === '/clients' ? css.active : undefined}>{t('Clients')}</Link>
    //                 </li>
    //                 <li>
    //                     <Link to="/users" onClick={()=>clickLink('/users')}
    //                           className={props.app.activeMenuTab === '/users' ? css.active : undefined}>{t('Users')}</Link>
    //                 </li>
    //                 <li>
    //                     <Link to="/cc">{t('Clearing Companies')}</Link>
    //                 </li>
    //                 <li>
    //                     <Link to="/c2c">{t('Click-2-Call')}</Link>
    //                 </li>
    //                 <li>
    //                     <Link to="/transactions">{t('Transactions')}</Link>
    //                 </li>
    //                 <li>
    //                     <Link to="/kyc">{t('KYC')}</Link>
    //                 </li>
    //                 <li>
    //                     <Link to="/affiliates">{t('Affiliates')}</Link>
    //                 </li>
    //                 <li>
    //                     <Link to="/campaigns">{t('Campaigns')}</Link>
    //                 </li>
    //                 <li>
    //                     <Link to="/brands">{t('Brands')}</Link>
    //                 </li>
    //                 <li>
    //                     <Link to="/desks">{t('Desks')}</Link>
    //                 </li>
    //
    //             </ul>
    //
    //         </>
    //
    //
    //     }
    //
    // </nav>
}

export default SideMenu;