import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import Campaigns from "./Campaigns";
import {getCampaigns, setLoading,removeCamp} from "../../redux/campaignsReducer";
import {setLoading as clientsSetLoading} from "../../redux/clientsReducer";
import {getClients} from "../../redux/clientsReducer";

const CampaignsContainer : React.FC = (props) => {
    return (
        <Campaigns {...props}/>
    );
};

let mapStateToProps:any = (state:any) => {
    return {
        auth: state.auth,
        // users: state.users,
        campaigns: state.campaigns,
    }
};

export default compose(connect(mapStateToProps,{setLoading,getCampaigns,clientsSetLoading,getClients,removeCamp}))(CampaignsContainer);
