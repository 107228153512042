export const EN = {
    "":"",
    "addUser": {
        "Login":"Please enter new user login",
        "login_error":"Please input login!",
        "Add new user":"Add new user",
        "User Added Successfully!": "User Added Successfully!",
        "We advice you to setup this user's permissions": "We advice you to setup this user\'s permissions",
        "Set Permissions": "Set Permissions",
        "Go to users list": "Go to users list",
        "Close/Add One More User": "Close/Add One More User",


        "Email":"Email",
        "Password":"Password",
        "Full Name":"Full Name",
        // "Last Name":"Last Name",
        "Active":"Active",
        "Desk":"Desk",
        "Role":"Role",
        "Brand":"Brand",
        "Supervisor":"Supervisor",
        "Add User":"Add User",
        "Reset":"Reset",
        "same email":"Account with this email already exists"
    },
    "addClient": {
        "Login":"Please enter new user login",
        "login_error":"Please input login!",
        "Add new client":"Add new client",
        "Client Added Successfully!": "Client Added Successfully!",
        "We advice you to setup this client's permissions": "We advice you to setup this client\'s permissions",
        "Set Permissions": "Set Permissions",
        "Go to clients list": "Go to clients list",
        "Close/Add One More Client": "Close/Add One More Client",
        "Email":"Email",
        "Password":"Password",
        "Full Name":"Full Name",
        "Phone":"Phone",
        "Active":"Active",
        "Desk":"Desk",
        "Role":"Role",
        "Brand":"Brand",
        "Supervisor":"Supervisor",
        "Add Client":"Add Client",
        "Reset":"Reset",
        "same email":"Account with this email already exists",
        "Campaign": "Campaign",
        "Status": "Status",
        "Type": "Type",
        "Assigned To": "Assigned To",
        "Country": "Country",
        "Address": "Address",
        // "srdgdsrg": "rgdrdgdrg",
        // "srdgdsrg": "rgdrdgdrg",
        // "srdgdsrg": "rgdrdgdrg",
    },
    "editUser": {
        "Login":"Please enter new user login",
        "login_error":"Please input login!",
        "Add new user":"Add new user",
        "User Added Successfully!": "User Added Successfully!",
        "We advice you to setup this user's permissions": "We advice you to setup this user\'s permissions",
        "Set Permissions": "Set Permissions",
        "Go to users list": "Go to users list",
        "Close/Add One More User": "Close/Add One More User",


        "Email":"Email",
        "Password":"Password",
        "Full Name":"Full Name",
        // "Last Name":"Last Name",
        "Active":"Active",
        "Desk":"Desk",
        "Role":"Role",
        "Brand":"Brand",
        "Supervisor":"Supervisor",
        "Add User":"Add User",
        "Reset":"Reset",
        "same email":"Account with this email already exists"
    },
    "editClient": {
        "Login":"Please enter new user login",
        "login_error":"Please input login!",
        "Edit client":"Edit client",
        "Client Added Successfully!": "Client Added Successfully!",
        "We advice you to setup this client's permissions": "We advice you to setup this client\'s permissions",
        "Set Permissions": "Set Permissions",
        "Go to clients list": "Go to clients list",
        "Close/Add One More Client": "Close/Add One More Client",
        "Email":"Email",
        "Password":"Password",
        "Full Name":"Full Name",
        "Phone":"Phone",
        "Active":"Active",
        "Desk":"Desk",
        "Role":"Role",
        "Brand":"Brand",
        "Supervisor":"Supervisor",
        "Save":"Save",
        "Reset":"Reset",
        "same email":"Account with this email already exists",
        "Campaign": "Campaign",
        "Status": "Status",
        "Type": "Type",
        "Assigned To": "Assigned To",
        "Country": "Country",
        "Address": "Address",
        // "srdgdsrg": "rgdrdgdrg",
        // "srdgdsrg": "rgdrdgdrg",
        // "srdgdsrg": "rgdrdgdrg",
    },
    "sideMenu": {
        "Login": "Login",
        "SignUp": "SignUp",
        "Home": "Home",
        "Clients": "Clients",
        "Users": "Users",
        "Add User": "Add User",
        "Clearing Companies": "Clearing Companies",
        "Click-2-Call": "Click-2-Call",
        "Transactions": "Transactions",
        "KYC": "KYC",
        "Affiliates": "Affiliates",
        "Campaigns": "Campaigns",
        "Brands": "Brands",
        "Desks": "Desks",
        "Users Hierarchy": "Users Hierarchy",
    },
    "include": {
        "Exit": "Exit",
        "вапвап": "вкккккк",
    },
    "login": {
        "Authorization": "Authorization",
        "Email":"Email",
        "Password": "Password",
        "Remember me": "Remember me",
        "Enter": "Enter",
        "login and/or password are wrong": "login and/or password are wrong",
        // "вапвап": "вкккккк",
        // "вапвап": "вкккккк",
        // "вапвап": "вкккккк",

    },
    "theme":{
        "Day":"Day",
        "Night":"Night",
    },
    "home":{
        "Home page":"Home page",
        "descr":"(It is planned to make all sorts of statistics here - number of leads, deposits, percentage of closures, amounts, etc.)",
        "Earned": "Earned",
        "CBs": "Chargebacks",
        // "drgdrtg": "sdrgdrgdrt",
    },
    "users":{
        "Users List": "Users List",
        "Add User": "+ Add user",
    },
    "clients":{
        "Clients List": "Clients List",
        "addClient": "+ Add client",
        "Name": "Name",
        "Phone": "Phone",
        "Email": "Email",
        "Desk": "Desk",
        "Status": "Status",
        "Create date": "Create date",
        "Password": "Password",
        "Active": "Active",
        "Online": "Online",
        "Last LogIn Date": "Last LogIn Date",
        "Last Action Date": "Last Action Date",
        "Last Action": "Last Action",
        "Type": "Type",
        "Brand": "Brand",
        "Assigned To": "Assigned To",
        "Country": "Country",
        "Campaign": "Campaign",
        "Utm campaign": "Utm campaign",
        "IP": "IP",
        "Comment": "Comment",
        "Additional info": "Additional info",
        "Last Comment Date": "Last Comment Date",
        "Action": "Action",
    },
    "client":{
        "Client Details View": "Client Details View",
        "Clients List": "Clients List"
    },
    "campaigns":{
        "Campaigns List": "Campaigns List",
        "Add Campaign": "+ Add Campaign",
        "campaignID": "Campaign ID",
        "campaignTitle": "Campaign Name",
        "affTitle": "Aff Name",
        "clientCount": "Clients Count",
        "See Clients": "See Clients",
        // "drgrdg": "rgrdrgdr",
    },
    "clearingCompanies":{
        "Clearing Companies List": "Clearing Companies List",
        "clearingCompanyID": "ID",
        "Add Clearing Company": "+ Add Clearing Company",
        // "uytre": "uytre",
        // "uytre": "uytre",
        // "uytre": "uytre",
        // "uytre": "uytre",
        // "uytre": "uytre",
        // "uytre": "uytre",
    },
    "addCampaign":{
        "Campaign Added Successfully!": "Campaign Added Successfully!",
        "We advice you to setup this client's permissions": "We advice you to setup this client\'s permissions",
        "Set Permissions": "Set Permissions",
        "Go to campaigns list": "Go to campaigns list",
        "Close/Add One More Campaign": "Close/Add One More Campaign",
        "Add new campaign": "Add new campaign",
        "Title": "Title",
        "Affiliate": "Affiliate",
        "Active": "Active",
        "Add Campaign": "Add Campaign",
        "Reset": "Reset",
        // "uytre": "uytre",
        // "uytre": "uytre",
        // "uytre": "uytre",
        // "uytre": "uytre",
        // "uytre": "uytre",
        // "uytre": "uytre",
    },
    "affs":{
        "Affiliates List":"Affiliates List",
        "ID":"ID",
        "Title":"Title",
        "Details":"Details",
        "Active":"Active",
        "addAff":"+ Add Aff",
        "Action":"Action",
        "remove":"remove",
    },
    "addAffiliate":{
        "Add new affiliate":"Add new affiliate",
        "Title":"Title",
        "Details":"Details",
        "Active":"Active",
        "Add Affiliate":"Add Affiliate",
        "Reset":"Reset",
        "Affiliate Added Successfully!":"Affiliate Added Successfully!",
        "Go to affiliates list":"Go to affiliates list",
        "Close/Add One More Affiliate":"Close/Add One More Affiliate",
    },




        // "dfgdfgdfg":"4r4rfr4r4r4rfrf4f4f4f",
        // "dfgdfgdfg":"4r4rfr4r4r4rfrf4f4f4f",
        // "dfgdfgdfg":"4r4rfr4r4r4rfrf4f4f4f",
        // "dfgdfgdfg":"4r4rfr4r4r4rfrf4f4f4f",
        // "dfgdfgdfg":"4r4rfr4r4r4rfrf4f4f4f",

}