import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import AddClient from "./AddClient";
import {setLoading, setNewClientAddedStatus, addClient} from "../../redux/clientsReducer";

const AddClientContainer : React.FC = (props) => {

    return (
        <AddClient {...props}/>
    );
};

let mapStateToProps:any = (state:any) => {
    return {
        auth: state.auth,
        clients: state.clients,
        app: state.app,
        desks: state.desks,
        brands: state.brands,
        statuses: state.statuses,
        campaigns: state.campaigns,
        countries: state.countries,
    }
};

export default compose(connect(mapStateToProps,{addClient,setNewClientAddedStatus,setLoading}))(AddClientContainer);
